import * as Types from "../../../../../apollo/graphql.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type GetCollectionTagsAndCategoriesQueryVariables = Types.Exact<{
  collectionId: Types.Scalars["String"];
}>;

export type GetCollectionTagsAndCategoriesQuery = {
  getPublicCollectionDetails: {
    __typename: "Collection";
    id: string;
    userTags: Array<{
      __typename: "Tag";
      id: string;
      name: string;
      slug: string;
      count: number;
    }> | null;
    userCategories: Array<{
      __typename: "Category";
      id: string;
      name: string;
      slug: string;
      type: string;
    }> | null;
  };
};

export const GetCollectionTagsAndCategoriesDocument = gql`
  query GetCollectionTagsAndCategories($collectionId: String!) {
    getPublicCollectionDetails(collectionId: $collectionId) {
      id
      userTags {
        id
        name
        slug
        count
      }
      userCategories {
        id
        name
        slug
        type
      }
    }
  }
`;

/**
 * __useGetCollectionTagsAndCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCollectionTagsAndCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionTagsAndCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionTagsAndCategoriesQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useGetCollectionTagsAndCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionTagsAndCategoriesQuery,
    GetCollectionTagsAndCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCollectionTagsAndCategoriesQuery,
    GetCollectionTagsAndCategoriesQueryVariables
  >(GetCollectionTagsAndCategoriesDocument, options);
}
export function useGetCollectionTagsAndCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionTagsAndCategoriesQuery,
    GetCollectionTagsAndCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCollectionTagsAndCategoriesQuery,
    GetCollectionTagsAndCategoriesQueryVariables
  >(GetCollectionTagsAndCategoriesDocument, options);
}
export type GetCollectionTagsAndCategoriesQueryHookResult = ReturnType<
  typeof useGetCollectionTagsAndCategoriesQuery
>;
export type GetCollectionTagsAndCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetCollectionTagsAndCategoriesLazyQuery
>;
export type GetCollectionTagsAndCategoriesQueryResult = Apollo.QueryResult<
  GetCollectionTagsAndCategoriesQuery,
  GetCollectionTagsAndCategoriesQueryVariables
>;
