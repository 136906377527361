import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import * as React from "react";

import MultilineText from "@hl/base-components/lib/MultilineText";
import {
  Refresh2 as RefreshIcon,
  Screen as FullScreenIcon,
  Image as ImageIcon,
  Expand as ExpandIcon,
  Minimize as MinimizeIcon,
} from "@hl/base-components/lib/assets/icons.generated";
import { LinkExternal01 } from "@hl/base-components/lib/assets/icons.generated/HDS/Duotone icons/General";
import useMantineMediaQueries from "@hl/base-components/lib/hooks/useMantineMediaQueries";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { GenArtUrlIFrame } from "@hl/shared-features/lib/features/gen-art/GenArtIFrame";
import { ResizedImage } from "@hl/shared-features/lib/features/image";
import { MarketplaceToken } from "@hl/shared-features/lib/features/marketplace/utils";
import useGenArtAspectRatio from "@hl/shared-features/lib/hooks/useAspectRatio";
import {
  ActionIcon,
  Box,
  Flex,
  Group,
  Modal,
  Tooltip,
  createStyles,
  Stack,
  useMantineTheme,
  Center,
} from "@mantine/core";
import {
  useDebouncedValue,
  useDisclosure,
  useElementSize,
  useFullscreen,
  useMediaQuery,
  useViewportSize,
} from "@mantine/hooks";
import { IconArrowNarrowLeft } from "@tabler/icons";

import { getDetailPageUrl } from "~config";
import {
  InternalLink,
  useLinkStyles,
} from "~features/MintPage/components/InternalLink";
import TokenDetailsCardSection from "~features/token-details/TokenDetailsCardSection";
import useMintState from "~hooks/useMintState";
import useMintTokensState from "~hooks/useMintTokensState";

type StyleProps = {
  isExpanded: boolean;
  aspectRatio?: string;
  isHover: boolean;
  showToolbarOnHover: boolean;
  responsive: boolean;
  hasMarketplaceButtons?: boolean;
  isVertical?: boolean;
  isMultiContractEdition?: boolean;
};

export const useToolbarStyles = createStyles(
  (
    theme,
    {
      isExpanded,
      isHover,
      showToolbarOnHover,
      responsive,
      hasMarketplaceButtons,
      isMultiContractEdition,
    }: StyleProps
  ) => {
    return {
      fullHeight: {
        maxHeight: "100%",
        height: "100% !important",
      },
      fullHeightWithoutToolbar: {
        maxHeight: `calc(100% - ${
          !isMultiContractEdition ? 79 : hasMarketplaceButtons ? 134 : 60
        }px)`,
        height: `calc(100% - ${
          !isMultiContractEdition ? 79 : hasMarketplaceButtons ? 134 : 60
        }px)!important`,
      },
      toolbar: {
        marginTop: 32,
        borderRadius: 10,
        border: `1px solid ${theme.colors.divider[0]}`,
        backgroundColor: theme.colors.baseBackground[0],
        ...(isExpanded && {
          position: "absolute",
          bottom: 20,
          marginBottom: 16,
        }),
        button: {
          height: 36,
          width: 36,
        },
        ...(showToolbarOnHover && {
          display: isHover ? "unset" : "none",
        }),
        ...(isExpanded &&
          responsive && {
            display: "none",
          }),
      },
      fullscreenButton: {
        [theme.fn.smallerThan("sm")]: {
          display: "none",
        },
      },
    };
  }
);

const TOKEN_INFO_HEIGHT = 112;
const TOKEN_NAVIGATION_HEIGHT = 69;
const SORTING_HEIGHT = 64;

const useStyles = createStyles(
  (theme, { aspectRatio, responsive, isVertical }: StyleProps) => {
    return {
      container: {
        display: "flex",
        alignItems: "center !important",
        aspectRatio,
        width: "100%",
        flexGrow: responsive ? undefined : 1,
        [theme.fn.largerThan("md")]: {
          width: isVertical ? "auto" : "100%",
          height: isVertical ? "100%" : "auto",
          // width: aspectRatio || isExpanded ? "auto" : "100%",
          maxWidth: "100%",
        },
        ...(responsive && {
          [theme.fn.largerThan("md")]: {
            width: "100%",
            maxWidth: "100%",
            maxHeight: "100%",
          },
          [theme.fn.smallerThan("md")]: {
            width: "calc(100% - 32px)",
            maxHeight: "66.67vh",
          },
        }),
      },
      iframe: {
        height: aspectRatio && !responsive ? "auto" : "100%",
      },
      previewImage: {
        objectFit: "scale-down",
      },
      expanded: {
        height: responsive ? "100vh" : "calc(100vh - 30px)",
        overflow: "hidden",
      },
      closeBtn: {
        position: "absolute",
        top: 10,
        right: 10,
      },
      modal: {
        ...(responsive && {
          padding: `0px !important`,
        }),
      },
      modalBody: {
        maxHeight: "100%",
        ...(responsive && {
          padding: 0,
          margin: 0,
        }),
      },
    };
  }
);

type GenArtMintedViewProps = {
  toolbarExtraClass?: string;
  showToolbarOnHover?: boolean;
  isReveal?: boolean;
  token?: MarketplaceToken;
  selectedTokenIndex: number;
  setSelectedTokenIndex: Dispatch<SetStateAction<number>>;
};

export const GenArtMintedViewApollo = ({
  showToolbarOnHover = false,
  toolbarExtraClass,
  selectedTokenIndex,
  setSelectedTokenIndex,
  isReveal = false,
}: GenArtMintedViewProps) => {
  const {
    collection,
    isImported,
    endedOrSoldOut,
    isAnySeriesMint,
    isCollectorChoiceMint,
    isMarketplaceNoticeShown,
  } = useMintState();
  const theme = useMantineTheme();
  const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
  const { isLarge } = useMantineMediaQueries({ withInitialValues: true });
  const { tokens, loadMore, refetch: refetchToken } = useMintTokensState();
  const {
    ref,
    width: containerWidth,
    height: containerHeight,
  } = useElementSize();
  const { classes: classesLink } = useLinkStyles();
  const showSortingControls =
    (isMarketplaceNoticeShown || isImported) && !isLarge;
  const token = tokens?.[selectedTokenIndex];
  const animationUrl = token?.animationUrl;
  const imageUrl = token?.imageUrl || collection?.collectionImage || "";
  const [isExpanded, { open, close: closeModal }] = useDisclosure(false);
  const [isHover, setHover] = useState(false);

  const { aspectRatio, ratio: genArtRatio } = useGenArtAspectRatio(
    collection?.generativeDetails?.captureSettings.viewPort
  );
  const { height, width } = useViewportSize();
  // re-render iframe when height or width changes
  const [debounced] = useDebouncedValue(height + width, 500);
  const [initialViewport, setInitialViewport] = useState(0);

  const showTokenInfo = token && endedOrSoldOut && !isLarge;
  const containerRatio =
    containerWidth /
    (containerHeight -
      (isLarge
        ? TOKEN_NAVIGATION_HEIGHT
        : (showTokenInfo ? TOKEN_INFO_HEIGHT : 0) -
          (showSortingControls ? SORTING_HEIGHT : 0)));
  const boundVertically = containerRatio > genArtRatio;
  const responsive = !!collection?.generativeDetails?.isResponsive;
  const { classes, cx } = useStyles({
    isExpanded,
    aspectRatio,
    isHover,
    showToolbarOnHover,
    responsive,
    isVertical: boundVertically,
  });
  useEffect(() => {
    if (!debounced || !responsive) {
      return;
    }
    if (debounced && initialViewport === 0) {
      setInitialViewport(debounced);
      return;
    }
    if (debounced !== initialViewport) {
      refreshIframe();
    }
  }, [debounced, initialViewport, responsive]);
  const moveToToken = useCallback(
    (moveFor: number) => {
      const newIndex = selectedTokenIndex + moveFor;
      if (newIndex < 0 || !tokens) {
        return;
      }
      if (newIndex >= tokens.length) {
        loadMore();
      }
      setSelectedTokenIndex((index) => index + moveFor);
    },
    [selectedTokenIndex, tokens]
  );

  useEffect(() => {
    if ((tokens?.length ?? 0) <= selectedTokenIndex + 1) {
      setSelectedTokenIndex(0);
    }
  }, [tokens, selectedTokenIndex]);

  const enableMintGenSeriesControls =
    collection?.flagVariations.enableMintGenSeriesControls;

  const enableRestrictiveFlags =
    !!collection?.flagVariations.enableIframeRestrictiveFlags;
  const allowIframeSameOrigin =
    !!collection?.flagVariations.allowIframeSameOrigin;

  const iconSize = { width: 16, height: 16 };
  const hasMarketplaceButtons =
    (isCollectorChoiceMint || isAnySeriesMint) && !!token;
  const { classes: toolbarClasses } = useToolbarStyles({
    isExpanded,
    aspectRatio,
    isHover,
    showToolbarOnHover,
    responsive,
    hasMarketplaceButtons,
  });
  const { ref: iframeRef, toggle: toggleFullscreen } = useFullscreen();
  const [ver, setVer] = useState(1);
  const [isShowingImage, setShowingImage] = useState(false);

  const showImage = useCallback(() => {
    open();
    setShowingImage(true);
  }, [open]);

  const close = useCallback(() => {
    closeModal();
    setShowingImage(false);
  }, [closeModal]);

  const refreshIframe = useCallback(() => {
    setVer((ver) => ver + 1);
  }, []);
  const content = (
    <Flex
      pos="relative"
      direction="column"
      w="100%"
      h={isExpanded ? "100vh" : isReveal ? "100%" : "100%"}
      mah={isLarge ? "100%" : "calc(100% - 64px)"}
      align="center"
      justify="center"
      ref={ref}
    >
      <Box
        className={classes.container}
        maw={!isExpanded && !isTablet ? "600px" : undefined}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {animationUrl ? (
          <GenArtUrlIFrame
            url={animationUrl + (isImported ? "" : `&ver=${ver}`)}
            aspectRatio={aspectRatio}
            iframeRef={iframeRef}
            bordered={false}
            extraClass={classes.iframe}
            enableRestrictiveFlags={enableRestrictiveFlags}
            allowIframeSameOrigin={allowIframeSameOrigin}
          />
        ) : (
          <ResizedImage
            src={imageUrl}
            width="100%"
            fit="contain"
            // onLoad={(e) => console.log('metadata',e)}
            height={isTablet ? `calc(100vh - 208px)` : "auto"}
          />
        )}
      </Box>
      {animationUrl && !endedOrSoldOut && (
        <Box
          p={4}
          className={cx(toolbarClasses.toolbar, toolbarExtraClass)}
          w="auto"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Group spacing={4}>
            {!enableMintGenSeriesControls && (
              <Tooltip label="Refresh">
                <ActionIcon onClick={refreshIframe}>
                  <RefreshIcon {...iconSize} />
                </ActionIcon>
              </Tooltip>
            )}
            {!isExpanded && (
              <Tooltip label="Expand">
                <ActionIcon onClick={open}>
                  <ExpandIcon {...iconSize} />
                </ActionIcon>
              </Tooltip>
            )}
            <Tooltip
              label="Fullscreen"
              className={toolbarClasses.fullscreenButton}
            >
              <ActionIcon onClick={toggleFullscreen}>
                <FullScreenIcon {...iconSize} />
              </ActionIcon>
            </Tooltip>
            {imageUrl && !enableMintGenSeriesControls && (
              <Tooltip label="Preview image">
                <ActionIcon onClick={showImage}>
                  <ImageIcon {...iconSize} />
                </ActionIcon>
              </Tooltip>
            )}
            <Tooltip label="Open in a new window">
              <ActionIcon
                onClick={() =>
                  window.open(animationUrl, "_blank", "noreferrer")
                }
              >
                <LinkExternal01 {...iconSize} />
              </ActionIcon>
            </Tooltip>
          </Group>
        </Box>
      )}
      {showTokenInfo && (
        <Stack spacing="sm" pt={12} miw={280}>
          <Flex
            w={isTablet ? 440 : "100%"}
            justify="space-between"
            align="center"
          >
            <ActionIcon
              onClick={() => moveToToken(-1)}
              size={48}
              disabled={selectedTokenIndex === 0}
            >
              <IconArrowNarrowLeft />
            </ActionIcon>
            <InternalLink
              className={classesLink.grow}
              to={collection ? getDetailPageUrl(collection, token.tokenId) : ""}
            >
              <MultilineText size="xl" weight={WEIGHT_BOLD} numLines="2">
                {token?.name || `#${token?.tokenId}`}
              </MultilineText>
            </InternalLink>
            <ActionIcon
              onClick={() => moveToToken(1)}
              size={48}
              disabled={selectedTokenIndex === (tokens?.length ?? 1) - 1}
            >
              <IconArrowNarrowLeft style={{ transform: "rotate(180deg)" }} />
            </ActionIcon>
          </Flex>
          {hasMarketplaceButtons && (
            <Center>
              <TokenDetailsCardSection
                token={token}
                refetchToken={() => refetchToken?.()}
              />
            </Center>
          )}
        </Stack>
      )}
    </Flex>
  );
  if (isExpanded) {
    return (
      <Modal
        opened={isExpanded}
        onClose={close}
        fullScreen
        withCloseButton={false}
        centered
        classNames={{
          modal: classes.modal,
          body: classes.modalBody,
        }}
      >
        <div className={classes.expanded}>
          {isShowingImage && imageUrl ? (
            <Flex justify="center" align="center" pos="relative" h="100%">
              <img
                className={cx([classes.container, classes.previewImage])}
                src={imageUrl}
              />
            </Flex>
          ) : (
            content
          )}
        </div>
        <ActionIcon className={classes.closeBtn} onClick={close}>
          <MinimizeIcon />
        </ActionIcon>
      </Modal>
    );
  }

  return content;
};
