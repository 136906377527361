import { memo, useCallback, useState } from "react";

import { STATUS_COLOR, TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { useAuth } from "@hl/shared-features/lib/features/auth";
import { SignInButton } from "@hl/shared-features/lib/features/auth/SignInButton";
import { useModalStack } from "@hl/shared-features/lib/features/modal";
import { useEns } from "@hl/shared-features/lib/hooks/useEns";
import {
  Box,
  Button,
  Divider,
  Group,
  NumberInput,
  Space,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { isAddress } from "viem";

import ErrorBox from "~features/MintPage/MintVector/ErrorBox";
import TransferCompleteBox from "~features/MintPage/transfer/TransferCompleteBox";
import TransferInProgressBox from "~features/MintPage/transfer/TransferInProgressBox";
import TransferTokenBox from "~features/MintPage/transfer/TransferTokenBox";
import TransferWarningBox from "~features/MintPage/transfer/TransferWarningBox";
import { useTransfer } from "~features/MintPage/transfer/useTransfer";

import {
  _CollectionType,
  NftContractStandard,
} from "../../../apollo/graphql.generated";
import {
  GetSalePageCollectionQuery,
  GetSalePageMintVectorsQuery,
  PublicUserProfileQuery,
} from "../queries.graphql.generated";

export type MintVector =
  GetSalePageMintVectorsQuery["getMintVectorsByCollection"][0];
export type Collection = GetSalePageCollectionQuery["getPublicCollection"];

export type TransferModalData = {
  from: string;
  collectionId: string;
  collectionType: _CollectionType;
  chainId: number;
  tokenId: string;
  collectionAddress: string;
  listingAmount?: string;
  listingSymbol?: string;
  onTransferComplete: () => void;
  account?: PublicUserProfileQuery["getPublicAccountSettings"];
  collectionName: string;
  tokenName: string;
  tokenImageUrl: string;
  standard: NftContractStandard;
  amountOwned?: number | null;
};

export const TransferModal = memo(({ data }: { data: TransferModalData }) => {
  const { popModal } = useModalStack();
  const { authenticated } = useAuth();
  const [toAddressResolved, setToAddressResolved] = useState<string>("");
  const [inputToAddress, setInputToAddress] = useState<string>("");
  const [isEnsAddress, setIsEnsAddress] = useState(false);
  const [numTokensToTransfer, setNumTokensToTransfer] = useState(1);

  const {
    collectionId,
    collectionType,
    chainId,
    collectionAddress,
    from,
    tokenId,
    listingSymbol,
    listingAmount,
    onTransferComplete,
    account,
    tokenImageUrl,
    tokenName,
    collectionName,
    standard,
    amountOwned,
  } = data;

  const numTokensOwned = amountOwned ?? 1;

  const {
    resolve: resolveEns,
    loading: loadingEns,
    error: errorEns,
    isEns,
  } = useEns();

  const {
    error: errorTrx,
    transfer,
    buttonLoading,
    buttonLabel,
    txInProgress,
    txHash,
    txDone,
  } = useTransfer(
    collectionId,
    collectionType,
    tokenId,
    chainId,
    from,
    collectionAddress,
    onTransferComplete,
    numTokensToTransfer,
    standard
  );

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const addressInput = event.target.value.toString() || "";
      let addressResolved = addressInput;
      if (isEns(addressInput)) {
        setIsEnsAddress(true);
        addressResolved = await resolveEns(addressInput);
      } else {
        setIsEnsAddress(false);
      }
      setInputToAddress(addressInput);
      setToAddressResolved(addressResolved);
    },
    [resolveEns, isEns]
  );

  return (
    <Box mx="auto">
      <form>
        <Stack spacing="md" mt="sm">
          <TransferTokenBox
            tokenImageUrl={tokenImageUrl}
            tokenName={tokenName}
            collectionName={collectionName}
            account={account}
            listingAmount={listingAmount}
            listingSymbol={listingSymbol}
          />

          {!txInProgress && !txDone && (
            <Stack>
              {standard === NftContractStandard.ERC1155 && (
                <Box>
                  <NumberInput
                    label="Number of tokens to transfer:"
                    placeholder="1"
                    onChange={(value: number) =>
                      setNumTokensToTransfer(parseInt(String(value)))
                    }
                    defaultValue={1}
                    value={numTokensToTransfer}
                    min={1}
                    max={numTokensOwned}
                    styles={{
                      input: {
                        paddingRight: 24,
                      },
                      rightSection: {
                        paddingRight: 0,
                      },
                    }}
                  />
                  <Text
                    size="xs"
                    color={
                      numTokensToTransfer > numTokensOwned
                        ? STATUS_COLOR.ERROR
                        : TEXT_COLOR.SECONDARY
                    }
                    mt={8}
                  >
                    You can transfer up to {numTokensOwned} token
                    {/*{numTokensOwned === 1 ? "" : "s"}*/}
                  </Text>
                </Box>
              )}
              <Box>
                <TextInput
                  label="Transfer to:"
                  placeholder="Wallet address or ENS"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") event.preventDefault();
                  }}
                  onChange={handleChange}
                />
                {isEnsAddress && (
                  <Text
                    size="xs"
                    color={errorEns ? STATUS_COLOR.ERROR : TEXT_COLOR.SECONDARY}
                    mt={8}
                  >
                    {loadingEns
                      ? "Loading..."
                      : !errorEns
                      ? `Resolves to ${toAddressResolved}`
                      : errorEns}
                  </Text>
                )}
              </Box>
              <TransferWarningBox hasListings={!!listingAmount} />
            </Stack>
          )}

          {txInProgress && (
            <TransferInProgressBox
              txHash={txHash}
              chainId={chainId}
              address={inputToAddress}
            />
          )}
          {txDone && (
            <TransferCompleteBox
              txHash={txHash}
              chainId={chainId}
              address={inputToAddress}
            />
          )}
        </Stack>
      </form>

      <Divider my={16} mx={-16} />
      <Group grow>
        {!authenticated ? (
          <SignInButton fullWidth size="lg" />
        ) : (
          <Button
            fullWidth
            size="xl"
            loading={buttonLoading}
            disabled={!isAddress(toAddressResolved)}
            onClick={() => {
              if (txDone) popModal();
              if (numTokensToTransfer > numTokensOwned) return;
              else transfer(toAddressResolved);
            }}
          >
            {buttonLabel || "Transfer"}
          </Button>
        )}
      </Group>
      {errorTrx && (
        <>
          <Space h={4} />
          <ErrorBox message={errorTrx} />
        </>
      )}
    </Box>
  );
});
