import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Flex, Popover } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
const HoverCardHighlight = ({ onBeforeOpen, canOpen = true, targetContent, children, width = 360, targetWrapperSx, }) => {
    const [readyToOpen, { close, open }] = useDisclosure(false);
    const [opened, setOpened] = useState(false);
    const [isTextHover, setIsTextHover] = useState(false);
    const [isTooltipHover, setIsTooltipHover] = useState(false);
    const textTimeoutRef = useRef();
    const tooltipTimeoutRef = useRef();
    const targetRef = useRef(null);
    const [beforeOpenCalled, setBeforeOpenCalled] = useState(false);
    useEffect(() => {
        if (!isTooltipHover && !isTextHover) {
            close();
            setOpened(false);
            return;
        }
        if (!canOpen && !beforeOpenCalled) {
            onBeforeOpen();
            setBeforeOpenCalled(true);
            return;
        }
        if (!readyToOpen) {
            open();
        }
    }, [
        isTooltipHover,
        isTextHover,
        readyToOpen,
        canOpen,
        onBeforeOpen,
        beforeOpenCalled,
        open,
        close,
        setOpened,
        setBeforeOpenCalled,
    ]);
    const [position, setPosition] = useState("top");
    const checkPosition = () => {
        if (targetRef.current) {
            const rect = targetRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const distanceToBottom = windowHeight - rect.bottom;
            const distanceToTop = rect.top;
            const isBottom = distanceToBottom > distanceToTop;
            setPosition(isBottom ? "bottom" : "top");
            setOpened(true);
        }
    };
    useEffect(() => {
        if (readyToOpen) {
            targetRef.current;
            checkPosition();
        }
    }, [readyToOpen, checkPosition]);
    const transition = {
        in: { opacity: 1, transform: "translateY(0)" },
        out: {
            opacity: 0,
            transform: `translateY(${position === "top" ? "-6" : "6"}%)`,
        },
        common: { transformOrigin: position === "top" ? "top" : "bottom" },
        transitionProperty: "transform, opacity",
    };
    return (_jsxs(Popover, { width: width, shadow: "md", position: position, opened: canOpen && opened, withinPortal: true, transition: transition, transitionDuration: 200, onPositionChange: setPosition, styles: {
            dropdown: {
                border: "none",
                overflow: "hidden",
            },
        }, children: [_jsx(Popover.Target, { children: _jsx(Flex, { miw: 0, ref: targetRef, onMouseEnter: () => {
                        if (textTimeoutRef.current) {
                            clearTimeout(textTimeoutRef.current);
                        }
                        if (tooltipTimeoutRef.current) {
                            clearTimeout(tooltipTimeoutRef.current);
                        }
                        if (!opened) {
                            textTimeoutRef.current = setTimeout(() => setIsTextHover(true), 300);
                        }
                        else {
                            setIsTextHover(true);
                            setIsTooltipHover(false);
                        }
                    }, onMouseLeave: () => {
                        textTimeoutRef.current = setTimeout(() => setIsTextHover(false), 300);
                    }, sx: targetWrapperSx, children: targetContent }) }), _jsx(Popover.Dropdown, { onMouseEnter: () => {
                    if (tooltipTimeoutRef.current) {
                        clearTimeout(tooltipTimeoutRef.current);
                    }
                    if (isTextHover) {
                        setIsTooltipHover(true);
                    }
                }, onMouseLeave: () => (tooltipTimeoutRef.current = setTimeout(() => setIsTooltipHover(false), 300)), children: children })] }));
};
export default HoverCardHighlight;
