import { useCallback } from "react";

import {
  TransactionArgs,
  TransactionType,
} from "@hl/shared-features/lib/apollo/graphql.generated";
import { useOnChainTransaction } from "@hl/shared-features/lib/hooks/useOnChainTransaction";
import { BigNumber } from "ethers/lib/ethers";

import {
  _CollectionType,
  EvmTransferMetadata,
  NftContractStandard,
} from "../../../apollo/graphql.generated";

export const useTransfer = (
  collectionId: string,
  collectionType: _CollectionType,
  tokenId: string,
  chainId: number,
  fromAddress: string,
  collectionAddress: string,
  onTransferComplete: () => void,
  amount: number,
  standard: NftContractStandard
) => {
  const {
    error,
    startTransaction,
    buttonLabel,
    buttonLoading,
    txHash,
    txInProgress,
    isDone,
  } = useOnChainTransaction({
    collectionType,
    entityId: collectionId,
    refetchQueries: [],
    transactionType: TransactionType.EVM_TOKEN_TRANSFER,
    onFinish: onTransferComplete,
    finishDelay: 5000,
  });

  const transfer = useCallback(
    async (toAddress: string) => {
      const metadata: EvmTransferMetadata = {
        collectionId,
        tokenId,
        fromAddress,
        nftContractAddress: collectionAddress,
        toAddress,
      };
      const inputArgs =
        standard === NftContractStandard.ERC1155
          ? [
              fromAddress,
              toAddress,
              BigNumber.from(tokenId).toHexString(),
              amount,
              "0x",
            ]
          : [fromAddress, toAddress, BigNumber.from(tokenId).toHexString()];
      console.log(inputArgs);
      const args: TransactionArgs = {
        args: JSON.stringify(inputArgs),
        address: collectionAddress,
        chainId,
        method: "safeTransferFrom",
        value: "0",
      };
      return startTransaction({ args, metadata });
    },
    [
      collectionId,
      tokenId,
      fromAddress,
      collectionAddress,
      chainId,
      isDone,
      amount,
    ]
  );

  return {
    error,
    transfer,
    buttonLabel: isDone ? "Done" : buttonLabel,
    buttonLoading,
    txHash,
    txInProgress,
    txDone: isDone,
  };
};
