import { useEffect, useState } from "react";

import { UTILITY_COLOR } from "@hl/base-components/lib/theme/colors";
import CopyButton from "@hl/shared-features/lib/features/widgets/CopyButton";
import { maskAddress } from "@hl/shared-features/lib/utils/content";
import { getDFSContent, isDFSUri } from "@hl/shared-features/lib/utils/dfs";
import { Group, Text } from "@mantine/core";

export const TokenTraitContent = ({
  attributeValue,
  showDfsResolutionFailure,
}: {
  attributeValue: string;
  showDfsResolutionFailure?: boolean;
}) => {
  const isDfs = isDFSUri(attributeValue);
  const maskedAttributeValue = maskAddress(attributeValue, 12, 4);
  const errorLoadingMsg = `Error loading from ${maskedAttributeValue}`;
  const [dfsContent, setDfsContent] = useState<string>(maskedAttributeValue);

  useEffect(() => {
    if (isDfs) {
      getDFSContent(attributeValue)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((data: any) => {
          if (!!data) {
            setDfsContent(data);
          } else {
            if (showDfsResolutionFailure) {
              setDfsContent(errorLoadingMsg);
            }
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          console.error(error);
          if (showDfsResolutionFailure) {
            setDfsContent(errorLoadingMsg);
          }
        });
    }
  }, [attributeValue]);

  if (!isDfs) {
    return (
      <Text size="sm" title={attributeValue}>
        {attributeValue}
      </Text>
    );
  }

  return (
    <Group spacing={4} noWrap>
      <Text
        size="sm"
        title={attributeValue}
        color={
          dfsContent === errorLoadingMsg
            ? UTILITY_COLOR.DEPRECATION_WARN
            : undefined
        }
      >
        {dfsContent}
      </Text>
      {(dfsContent == errorLoadingMsg ||
        dfsContent == maskedAttributeValue) && (
        <CopyButton copyValue={attributeValue} />
      )}
    </Group>
  );
};
