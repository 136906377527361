import { CheckGreen } from "@hl/base-components/lib/assets/icons.generated";
import { STATUS_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import {
  buildTxUrl,
  networkLookup,
} from "@hl/shared-features/lib/utils/blockExplorer";
import { Anchor, Box, Card, Group, Stack, Text } from "@mantine/core";

const TransferCompleteBox = ({
  txHash = "",
  address,
  chainId,
}: {
  txHash?: string;
  address: string;
  chainId: number;
}) => {
  const txUrl = buildTxUrl(networkLookup(chainId).type, txHash);
  return (
    <Stack>
      <Stack spacing={4}>
        <Text component="label" size="lg" fw={WEIGHT_BOLD}>
          Transferred to:
        </Text>
        <Text size="lg" fw={WEIGHT_BOLD}>
          {address}
        </Text>
      </Stack>

      <Card
        bg={STATUS_COLOR.SUCCESS_WASH}
        sx={(theme) => ({
          borderColor: theme.fn.themeColor(STATUS_COLOR.SUCCESS),
        })}
      >
        <Group align="center" noWrap>
          <CheckGreen />
          <Box>
            <Text size="sm" fw={WEIGHT_BOLD} span>
              Transfer complete.{" "}
            </Text>
            <Text size="sm" span>
              View transaction{" "}
              <Anchor
                size="sm"
                fw={WEIGHT_BOLD}
                underline={false}
                href={txUrl}
                target="_blank"
                rel="noreferrer"
              >
                here
              </Anchor>
            </Text>
          </Box>
        </Group>
      </Card>
    </Stack>
  );
};

export default TransferCompleteBox;
