import { useCallback, useMemo, useState } from "react";
import { ethers } from "ethers";
import { ALCHEMY_API_KEY_ENS } from "../config";
export const useEns = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const ensUrl = `https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_API_KEY_ENS}`;
    const provider = useMemo(() => ALCHEMY_API_KEY_ENS
        ? new ethers.providers.JsonRpcProvider(ensUrl)
        : ethers.getDefaultProvider(), [ensUrl]);
    const isEns = (value) => value.endsWith(".eth");
    const resolve = useCallback(async (ensName) => {
        if (!isEns(ensName))
            return ensName;
        setLoading(true);
        setError("");
        try {
            const name = await provider.resolveName(ensName);
            if (!name) {
                setError("Unable to resolve ens name");
                return ensName;
            }
            return name;
        }
        catch (e) {
            setError("Error fetching ens name");
            return "";
        }
        finally {
            setLoading(false);
        }
    }, [provider]);
    return {
        resolve,
        loading,
        error,
        isEns,
    };
};
