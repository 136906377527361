import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo } from "react";
const formatNumber = (number) => {
    const formatter = Intl.NumberFormat("en");
    return formatter.format(number);
};
const EditionSize = ({ isSizeLoading, editionSize, useInfinitySymbol = true, }) => {
    if (isSizeLoading || editionSize === undefined) {
        return _jsx(_Fragment, { children: "\u2013" });
    }
    if (editionSize === null) {
        if (useInfinitySymbol) {
            return _jsx(_Fragment, { children: "\u221E" });
        }
        else {
            return _jsx(_Fragment, { children: "Unlimited" });
        }
    }
    else {
        return _jsx(_Fragment, { children: formatNumber(editionSize) });
    }
};
export default memo(EditionSize);
