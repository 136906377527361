import React, { Dispatch, SetStateAction, useEffect } from "react";

import FormatCrypto from "@hl/base-components/lib/FormatCrypto";
import {
  CLEAR_COLOR,
  OUTLINE_COLOR,
  PRIMARY_COLOR,
} from "@hl/base-components/lib/theme/button";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import {
  WEIGHT_BOLD,
  WEIGHT_NORMAL,
} from "@hl/base-components/lib/theme/typography";
import { User } from "@hl/shared-features/lib/features/auth/User";
import ListingSource from "@hl/shared-features/lib/features/marketplace/components/ListingSource";
import MarketplacePrice from "@hl/shared-features/lib/features/marketplace/components/MarketplacePrice";
import BidModalHighlight from "@hl/shared-features/lib/features/marketplace/modals/BidModal";
import BuyModalHighlight from "@hl/shared-features/lib/features/marketplace/modals/BuyModal";
import CancelListingModalHighlight, {
  CancelListingModalTitle,
} from "@hl/shared-features/lib/features/marketplace/modals/CancelListingModal";
import ListModalHighlight from "@hl/shared-features/lib/features/marketplace/modals/ListModal";
import ManageOffersModal from "@hl/shared-features/lib/features/marketplace/modals/ManageOffersModal";
import { useOwnerOffersQuery } from "@hl/shared-features/lib/features/marketplace/queries/marketplace.graphql.generated";
import { HighlightFields } from "@hl/shared-features/lib/features/marketplace/utils";
import { MarketplaceSelect } from "@hl/shared-features/lib/features/mint/MarketplaceSelect";
import { useModalStack } from "@hl/shared-features/lib/features/modal";
import { maskAddress } from "@hl/shared-features/lib/utils/content";
import {
  Box,
  Button,
  Flex,
  Grid,
  Loader,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useCollections, useListings } from "@reservoir0x/reservoir-kit-ui";

import { usePublicUserProfileQuery } from "~features/MintPage/queries.graphql.generated";
import TransferButton from "~features/MintPage/transfer/TransferButton";
import useMintState from "~hooks/useMintState";

import { SeriesToken } from "../../apollo/graphql.generated";
import MintCardButton, {
  MintButtonPosition,
} from "../MintPage/MintVector/MintCardButton";

type ListingHighlight = ReturnType<typeof useListings>["data"][0] &
  HighlightFields;

type Props = {
  token: Omit<SeriesToken, "attributes">;
  refetchToken: () => void;
  setHasOffers?: Dispatch<SetStateAction<boolean>>;
  setListingSource?: Dispatch<SetStateAction<string | undefined>>;
  isFooter?: boolean;
};

const TokenDetailsCardSection = ({
  token,
  refetchToken,
  setHasOffers,
  setListingSource,
  isFooter = false,
}: Props) => {
  const {
    mintVector,
    handleMint,
    address,
    chainId,
    collection,
    walletAddress,
    isMarketplaceEnabledForCollectionChain,
    isImported,
    marketplaceId,
    reservoirCollectionId,
    isSingleContractEdition,
  } = useMintState();
  const {
    tokenId,
    ownerAddress,
    userBalance,
    id,
    imageUrl,
    ownerEns,
    name,
    minted,
  } = token;
  const {
    data: listings,
    isFetchingInitialData: isLoadingListings,
    mutate: mutateListings,
  } = useListings({
    token: `${address}:${tokenId}`,
    status: "active",
    sortBy: "price",
    sortDirection: "asc",
    normalizeRoyalties: false,
  });
  useEffect(() => {
    setListingSource?.(listings?.[0]?.source?.domain as string);
  }, [listings]);
  const {
    data,
    loading: isLoadingBids,
    refetch: refetchBids,
  } = useOwnerOffersQuery({
    variables: {
      chainId,
      createdAfter: null,
      tokenId: tokenId ?? null,
      tokenAddress: address,
      aboveFloor: null,
      tokenOrCollectionName: null,
      sortDirection: null,
      sortBy: null,
      after: null,
      first: 10,
    },
    skip: !tokenId,
  });
  const isLoading = isLoadingBids || isLoadingListings;
  const tokenBids = data?.getOwnerOffers.edges;

  const { data: ownerAccount, loading } = usePublicUserProfileQuery({
    variables: {
      slug: ownerAddress || "",
    },
    skip: !ownerAddress,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    setHasOffers?.(!!tokenBids?.length);
  }, [tokenBids]);

  const { data: collectionMarketData } = useCollections({
    id: reservoirCollectionId,
  });
  const sortedBids = [...(tokenBids || [])].sort(
    (firstBid, secondBid) =>
      Number(secondBid.price?.amount?.native || 0) -
      Number(firstBid.price?.amount?.native || 0)
  );
  const highestBid = sortedBids?.[0];
  const floor = collectionMarketData?.[0]?.floorAsk?.price?.amount?.native ?? 0;
  const bidsAboveFloor = sortedBids?.filter(
    (bid) => parseFloat(bid.price?.amount?.native ?? 0) > floor
  );
  const hasListings = listings.length > 0;
  // TODO: remove mock when BE is fixed
  // const ub = userBalance || 1;
  const isOwner =
    (ownerAddress?.toLowerCase() === walletAddress?.toLowerCase() &&
      !!ownerAddress) ||
    !!userBalance;

  const theme = useMantineTheme();
  const isSmall = useMediaQuery(
    `(min-width: ${theme.breakpoints.sm}px) and (max-width: ${theme.breakpoints.md}px)`
  );
  const { pushModal } = useModalStack();

  const onMintClick = (fromChain: number) =>
    handleMint(fromChain, id, imageUrl || "");
  if ((!mintVector && !isImported) || !collection) {
    return null;
  }
  const listing = listings?.[0] as ListingHighlight;
  const hasBids = !!tokenBids && tokenBids.length > 0;
  const hasBidsOrLoading = isLoadingBids || hasBids;
  const noBids = !hasBids && !isLoadingBids;

  const account = ownerAccount?.getPublicAccountSettings;

  const userInfo = isFooter && ownerAddress && (
    <Text
      color={TEXT_COLOR.SECONDARY}
      size="xs"
      display="flex"
      sx={{ alignItems: "center" }}
    >
      {hasListings ? "Listed by" : "Owned by"}&nbsp;
      {loading ? (
        <Flex lh={0}>
          <Loader size={14} />
        </Flex>
      ) : account ? (
        <User
          avatarUrl={account.displayAvatar}
          displayName={account.displayName || ownerEns}
          walletAddresses={account.walletAddresses}
          verified={!!account.verified}
          imported={!!account.imported}
          enableLink
          textSize="xs"
          textColor={TEXT_COLOR.SECONDARY}
          avatarSize={18}
        />
      ) : (
        ownerEns || maskAddress(ownerAddress, 4, 4)
      )}
    </Text>
  );

  const domain = listings?.[0]?.source?.domain as string;
  const listingSourceInfo = isFooter &&
    !!domain &&
    domain !== "highlight.xyz" && (
      <ListingSource size="xs" domain={domain} color={TEXT_COLOR.SECONDARY} />
    );
  const noOffersText = (
    <Text size="xs" color={TEXT_COLOR.SECONDARY} mt={8}>
      You don’t have any offers for this item yet
    </Text>
  );

  if (isLoading) {
    return (
      <Flex py={10} w="100%" justify="center">
        <Loader />
      </Flex>
    );
  }

  if (isSingleContractEdition || !isMarketplaceEnabledForCollectionChain) {
    if (isOwner && tokenId && collection) {
      return (
        <Box mt={8}>
          <TransferButton
            onTransferComplete={refetchToken}
            token={token}
            account={account}
            listingAmount={listing?.price?.amount?.raw}
            listingSymbol={listing?.price?.currency?.symbol}
          />
        </Box>
      );
    }

    return null;
  }

  if (isOwner && tokenId && collection) {
    return (
      <Stack w="100%" spacing={0}>
        {(hasBidsOrLoading || (noBids && isFooter)) && (
          <Stack
            spacing={0}
            mb={isFooter ? 16 : 8}
            pb={isFooter ? 12 : 8}
            justify="space-between"
            w="100%"
            sx={{
              borderBottom: isFooter
                ? `1px solid ${theme.colors.divider[0]}`
                : undefined,
            }}
          >
            {noBids && noOffersText}
            {hasBids && (
              <>
                <Text fw={WEIGHT_BOLD} size="sm">
                  You have {tokenBids.length} offer
                  {tokenBids.length === 1 ? "" : "s"} for this item
                </Text>
                <Text size="sm" color={TEXT_COLOR.SECONDARY}>
                  The highest offer is{" "}
                  <FormatCrypto
                    onlyText
                    amount={highestBid?.price?.amount?.raw}
                    symbol={highestBid?.price?.currency?.symbol}
                  />
                  <br />
                  {bidsAboveFloor?.length} offer
                  {bidsAboveFloor?.length === 1 ? " is" : "s are"} higher than
                  the floor price
                </Text>
              </>
            )}
            {isLoadingBids && <Loader />}
          </Stack>
        )}
        <Flex
          direction={hasBidsOrLoading && hasListings ? "column" : "row"}
          columnGap={16}
        >
          {hasBids && (
            <Button
              size="lg"
              color={hasListings ? PRIMARY_COLOR : OUTLINE_COLOR}
              w="100%"
              onClick={() =>
                pushModal(
                  <ManageOffersModal
                    collectionData={{
                      id: collection?.id,
                      onchainId: collection?.onchainId,
                      address: collection?.address || "",
                      chainId: collection?.chainId,
                      name: collection?.name || "",
                      marketplaceId: marketplaceId,
                    }}
                    tokenId={tokenId ?? ""}
                    onCompleted={() => refetchToken?.()}
                  />,
                  {
                    size: "100%",
                    title: (
                      <Text fw={WEIGHT_BOLD}>
                        Manage offers for {name} · {collection?.name}
                      </Text>
                    ),
                  }
                )
              }
            >
              Manage offers
            </Button>
          )}
          {!hasListings ? (
            <Button
              size="lg"
              w="100%"
              disabled={isLoadingListings}
              onClick={() =>
                pushModal(
                  <ListModalHighlight
                    src={imageUrl || ""}
                    collectionData={collection}
                    tokenId={tokenId || ""}
                    onCompleted={() => mutateListings()}
                  />,
                  {
                    size: "lg",
                    title: (
                      <Text size="md" fw={WEIGHT_BOLD}>
                        Create listing
                      </Text>
                    ),
                  }
                )
              }
            >
              List for sale
            </Button>
          ) : (
            <Button
              size={hasBidsOrLoading ? "xs" : "lg"}
              w="100%"
              color={hasBidsOrLoading ? CLEAR_COLOR : OUTLINE_COLOR}
              fw={hasBidsOrLoading ? WEIGHT_NORMAL : WEIGHT_BOLD}
              onClick={() =>
                pushModal(
                  <CancelListingModalHighlight
                    collectionId={collection?.id}
                    chainId={chainId}
                    id={listings[0].id}
                    imageUrl={imageUrl || ""}
                    collectionName={collection?.name}
                    tokenId={tokenId || ""}
                    floorPrice={
                      collectionMarketData?.[0]?.floorAsk?.price?.amount?.native
                    }
                    onCancel={() => {
                      refetchBids();
                      mutateListings();
                    }}
                  />,
                  {
                    size: "lg",
                    title: CancelListingModalTitle(),
                  }
                )
              }
            >
              <Text
                color={
                  hasBidsOrLoading ? TEXT_COLOR.SECONDARY : TEXT_COLOR.PRIMARY
                }
                size={hasBidsOrLoading ? "xs" : "sm"}
                fw={hasBidsOrLoading ? WEIGHT_NORMAL : WEIGHT_BOLD}
                display="flex"
              >
                Cancel listing ·&nbsp;
                <MarketplacePrice price={listing.price} unstyled />
              </Text>
            </Button>
          )}
        </Flex>
        {!isFooter && !tokenBids?.length && !isLoadingBids && (
          <Text size="xs" color={TEXT_COLOR.SECONDARY} mt={8}>
            You don’t have any offers for this item yet
          </Text>
        )}
        <Box mt={8}>
          <TransferButton
            onTransferComplete={refetchToken}
            token={token}
            account={account}
            listingAmount={listing?.price?.amount?.raw}
            listingSymbol={listing?.price?.currency?.symbol}
          />
        </Box>
        {isFooter && <Box mt={8}>{listingSourceInfo}</Box>}
      </Stack>
    );
  }

  if (tokenId && minted && collection) {
    return (
      <Stack w="100%" spacing={8}>
        <Box mx={-8}>
          <Grid gutter="md" w="100%" m={0}>
            <Grid.Col span={hasListings ? (isSmall ? 12 : 6) : 12} py={0}>
              <Button
                w="100%"
                px="xs"
                size="lg"
                color={hasListings ? OUTLINE_COLOR : PRIMARY_COLOR}
                onClick={() =>
                  pushModal(
                    <BidModalHighlight
                      tokenId={tokenId}
                      src={imageUrl || ""}
                      collectionData={collection}
                    />,
                    {
                      size: "lg",
                      title: (
                        <Text size="md" fw={WEIGHT_BOLD}>
                          Make an offer
                        </Text>
                      ),
                    }
                  )
                }
              >
                <Text
                  size="sm"
                  sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
                  color={!hasListings ? TEXT_COLOR.INVERTED_PRIMARY : undefined}
                >
                  Make offer
                </Text>
              </Button>
            </Grid.Col>
            {hasListings && (
              <Grid.Col span={isSmall ? 12 : 6} py={0} mt={isSmall ? 8 : 0}>
                <Button
                  w="100%"
                  px="xs"
                  size="lg"
                  onClick={() =>
                    pushModal(
                      <BuyModalHighlight
                        tokenId={tokenId!}
                        src={imageUrl || ""}
                        collectionData={collection}
                        orderId={listings[0].id}
                        onCompleted={() => refetchToken()}
                      />,
                      {
                        size: "lg",
                        title: (
                          <Text size="md" fw={WEIGHT_BOLD}>
                            Complete purchase
                          </Text>
                        ),
                      }
                    )
                  }
                >
                  <Text
                    color={TEXT_COLOR.INVERTED_PRIMARY}
                    size="sm"
                    sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
                  >
                    Buy ·{" "}
                    <FormatCrypto
                      amount={listing.price?.amount?.raw}
                      symbol={listing.price?.currency?.symbol}
                      onlyText
                    />
                  </Text>
                </Button>
              </Grid.Col>
            )}
          </Grid>
        </Box>
        {listingSourceInfo}
        {!listingSourceInfo && userInfo}
      </Stack>
    );
  }

  if (minted && tokenId) {
    return (
      <MarketplaceSelect
        address={address}
        tokenId={tokenId ?? ""}
        chainId={chainId}
        buttonProps={{
          size: "xl",
          fullWidth: true,
        }}
        collectionName={collection?.name ?? ""}
        collectionId={collection?.id ?? ""}
      />
    );
  }

  return (
    <MintCardButton
      showErrorIcon={false}
      onMintClick={onMintClick}
      showMintFee
      showGateRequirementsModal
      size="xl"
      fullWidth
      tokenId={id}
      disabled={!imageUrl ?? false}
      buttonPosition={MintButtonPosition.TokenDetails}
      enableCrossChainMint={collection.flagVariations.enableCrossChainMinting}
    />
  );
};

export default TokenDetailsCardSection;
