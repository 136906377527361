import React, { useCallback, useMemo } from "react";

import {
  ArweaveLogo,
  BrandJustLogo,
  EtherscanLogo,
  OpenseaLogo,
} from "@hl/base-components/lib/assets/icons.generated";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { ampli } from "@hl/shared-features/lib/ampli";
import { ViewElsewhereIcon } from "@hl/shared-features/lib/assets/icons.generated";
import {
  buildContractUrl,
  getBlockExplorerName,
  networkLookup,
} from "@hl/shared-features/lib/utils/blockExplorer";
import { buildOpenSeaTokenUrl } from "@hl/shared-features/lib/utils/opensea";
import { Anchor, Group, Text, UnstyledButton } from "@mantine/core";

import { EXTERNAL_URL, getMintPageUrl } from "~config";
import useMintState from "~hooks/useMintState";

import { InternalLink } from "../InternalLink";

import { DetailSectionLine } from "./DetailSectionLine";

type Props = {
  noBottomDivider?: boolean;
  token?: {
    id?: string;
    onChainImage?: string;
  } | null;
  isApollo?: boolean;
};

export const DetailSectionViewFields = ({
  token,
  noBottomDivider = false,
  isApollo,
}: Props) => {
  const {
    collection,
    chainId,
    address,
    isAnySeriesMint,
    totalSoldCount,
    generateArweaveLink,
    isMarketplaceEnabledForCollectionChain,
    isOpenSeaSupportedForCollectionChain,
    isSeriesMint,
  } = useMintState();
  const blockchain = networkLookup(chainId);
  const openSeaUrl =
    collection?.chainId && token?.id
      ? buildOpenSeaTokenUrl(address, token.id, blockchain.type)
      : collection?.openSeaCollectionUrl;

  const trackViewOnOpensea = useCallback(() => {
    if (!ampli.isLoaded || !collection) {
      return;
    }
    ampli.viewOnMarketplace({
      collectionId: collection.id,
      tokenId: token?.id ?? undefined,
      projectName: collection.name,
      marketplace: "OpenSea",
      pagePath: window.location.pathname,
    });
  }, [collection, token]);

  const browseLink = collection
    ? getMintPageUrl(collection, { marketplace: true })
    : "";

  const arweaveLink = generateArweaveLink(token?.onChainImage);

  const tokensText = useMemo(
    () => `token${totalSoldCount !== 1 ? "s" : ""}`,
    [totalSoldCount]
  );
  const mintedText = useMemo(
    () =>
      isAnySeriesMint
        ? "View collection on Highlight marketplace"
        : `${totalSoldCount || 0} ${tokensText} minted`,
    [isAnySeriesMint, totalSoldCount]
  );

  const browsePageLink = (child: JSX.Element) => {
    return isAnySeriesMint ? (
      <UnstyledButton component={InternalLink} to={browseLink}>
        {child}
      </UnstyledButton>
    ) : (
      child
    );
  };

  return (
    <>
      {collection?.id && !isApollo && (
        <DetailSectionLine
          leftContent={browsePageLink(
            <Group spacing={10} align="center" noWrap>
              <BrandJustLogo height={12} width={18} />
              <Text size="sm" sx={{ flexWrap: "nowrap", display: "flex" }}>
                {mintedText}
              </Text>
            </Group>
          )}
        />
      )}
      {!isMarketplaceEnabledForCollectionChain &&
        openSeaUrl &&
        isOpenSeaSupportedForCollectionChain && (
          <DetailSectionLine
            leftContent={
              <Group spacing={10} align="center" noWrap>
                <OpenseaLogo width={18} height={18} />
                <Text size="sm">View token on OpenSea</Text>
              </Group>
            }
            rightContent={<ViewElsewhereIcon />}
            link={openSeaUrl}
            onClick={trackViewOnOpensea}
          />
        )}
      <DetailSectionLine
        leftContent={
          <Group spacing={10} align="center" noWrap>
            <EtherscanLogo width={18} height={18} />
            <Text size="sm">{`View contract on ${getBlockExplorerName(
              blockchain.type
            )}`}</Text>
          </Group>
        }
        rightContent={<ViewElsewhereIcon />}
        link={buildContractUrl(blockchain.type, address)}
        noDivider={noBottomDivider && !arweaveLink}
      />
      {arweaveLink && (
        <DetailSectionLine
          leftContent={
            <Group spacing={10} align="center" noWrap>
              <ArweaveLogo width={18} height={18} />
              <Text size="sm">View metadata on Arweave</Text>
            </Group>
          }
          rightContent={<ViewElsewhereIcon />}
          link={arweaveLink}
          noDivider={noBottomDivider}
        />
      )}
      {isSeriesMint &&
        isApollo &&
        collection?.reveal &&
        !collection.flagVariations.sequentialDistributionSeries && (
          <Text size="xs" color={TEXT_COLOR.SECONDARY} mt={12}>
            We use an independently verifiable, deterministic algorithm to
            ensure random token selection is fair.{" "}
            <Anchor
              size="xs"
              weight="bold"
              href={`${EXTERNAL_URL.RANDOMIZATION_ALGO_HELP_ARTICLE}`}
            >
              Read more
            </Anchor>{" "}
            about how we ensure randomness is fair and decentralized.
          </Text>
        )}
    </>
  );
};
