import React, { useState } from "react";

import { InfoCircleFill } from "@hl/base-components/lib/assets/icons.generated";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { User } from "@hl/shared-features/lib/features/auth/User";
import EditionSize from "@hl/shared-features/lib/features/chain/EditionSize";
import OpenseaRegistryInfoModal from "@hl/shared-features/lib/features/openseaBlocklist/OpenseaBlocklistInfoModal";
import CopyButton from "@hl/shared-features/lib/features/widgets/CopyButton";
import CopyText from "@hl/shared-features/lib/features/widgets/CopyText";
import { networkLookup } from "@hl/shared-features/lib/utils/blockExplorer";
import { maskAddress } from "@hl/shared-features/lib/utils/content";
import { getMintVectorId } from "@hl/shared-features/lib/utils/getMintVectorId";
import { isUnlimitedSize } from "@hl/shared-features/lib/utils/unlimitedSize";
import {
  Group,
  Text,
  Anchor,
  useMantineTheme,
  Loader,
  Box,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { zeroAddress } from "viem";

import { EXTERNAL_URL } from "~config";
import useMintState from "~hooks/useMintState";

import {
  CollectionContractType,
  PriceType,
} from "../../../../apollo/graphql.generated";
import { BaseToken } from "../../index";
import { usePublicUserProfileQuery } from "../../queries.graphql.generated";

import {
  DetailSectionColumn,
  DetailSectionColumnProps,
} from "./DetailSectionColumn";

type DetailSectionProps = {
  token?: BaseToken;
};

export const DetailSection = ({ token }: DetailSectionProps) => {
  const [openseaBlocklistModalOpen, setOpenseaBlocklistModalOpen] =
    useState<boolean>(false);
  const {
    mintVector,
    collectionLoading,
    collection,
    address,
    chainId,
    isSeriesMint,
    isAnySeriesMint,
    mintVectorsLoading,
    totalSize,
    isImported,
    importedPlatformInfo,
  } = useMintState();
  const mintVectorIdProps = {
    isDirectMint: mintVector?.isDirectMint ?? false,
    vectorId: mintVector?.id ?? undefined,
    encodedOnchainId: mintVector?.onchainMintVectorId ?? undefined,
  };
  const theme = useMantineTheme();
  const isSmall = useMediaQuery(`(max-width: ${theme.breakpoints.sm - 1}px)`);
  const isLargeAndUp = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`);

  const blockchain = networkLookup(chainId);

  const maskedAddress = maskAddress(
    address,
    isSmall || isLargeAndUp ? 30 : 12,
    4
  );
  const currencyMaskedAddress =
    mintVector?.currency && mintVector.currency !== zeroAddress
      ? maskAddress(mintVector.currency, isSmall || isLargeAndUp ? 30 : 12, 4)
      : null;

  const mintVectorId =
    getMintVectorId(
      mintVectorIdProps.isDirectMint,
      mintVectorIdProps.vectorId,
      mintVectorIdProps.encodedOnchainId
    ) ?? "";
  const maskedMintVectorId = maskAddress(mintVectorId, 6, 4);

  const { data, loading } = usePublicUserProfileQuery({
    variables: {
      slug: token?.ownerAddress || "",
    },
    skip: !token?.ownerAddress,
    notifyOnNetworkStatusChange: true,
  });

  const account = data?.getPublicAccountSettings;
  const isERC1155 = collection?.standard === "ERC1155";
  const standardFormatted = collection?.standard
    ? collection.standard.replace("ERC", "ERC-")
    : "ERC-721";

  return (
    <>
      {token?.id && <DetailSectionItem title="Token ID" content={token?.id} />}
      {token?.ownerAddress && !isERC1155 && (
        <DetailSectionItem
          title="Owned by"
          content={
            loading ? (
              <Box lh={0}>
                <Loader size="xs" />
              </Box>
            ) : account ? (
              <User
                avatarUrl={account.displayAvatar}
                displayName={account.displayName || token.ownerEns}
                walletAddresses={account.walletAddresses}
                verified={!!account.verified}
                imported={!!account.imported}
                enableLink
                avatarSize={18}
              />
            ) : (
              token.ownerEns || maskAddress(token.ownerAddress, 4, 4)
            )
          }
        />
      )}

      <DetailSectionItem
        title="Contract address"
        content={
          <Group spacing={4} noWrap>
            {isSmall ? (
              <CopyText
                displayText={maskedAddress}
                copyValue={address}
              ></CopyText>
            ) : (
              maskedAddress
            )}
            <CopyButton copyValue={address} />
          </Group>
        }
      />
      {!token?.id && currencyMaskedAddress && mintVector?.currency && (
        <DetailSectionItem
          title="Payment token contract address"
          content={
            <Group spacing={4} noWrap>
              {isSmall ? (
                <CopyText
                  displayText={currencyMaskedAddress}
                  copyValue={mintVector?.currency}
                ></CopyText>
              ) : (
                currencyMaskedAddress
              )}
              <CopyButton copyValue={mintVector?.currency} />
            </Group>
          }
        />
      )}
      <DetailSectionItem title="Blockchain" content={blockchain.displayName} />
      <DetailSectionItem title="Token standard" content={standardFormatted} />
      {isAnySeriesMint ? (
        <DetailSectionItem
          title="Series Size"
          content={
            <EditionSize
              isSizeLoading={false}
              editionSize={totalSize === 0 ? null : totalSize}
              useInfinitySymbol={false}
            />
          }
        />
      ) : (
        <DetailSectionItem
          title="Edition size"
          content={
            <>
              {collection?.type === CollectionContractType.General ? (
                <EditionSize
                  isSizeLoading={false}
                  editionSize={1}
                  useInfinitySymbol={false}
                />
              ) : collectionLoading ? (
                <EditionSize
                  isSizeLoading={true}
                  editionSize={null}
                  useInfinitySymbol={false}
                />
              ) : (
                <EditionSize
                  isSizeLoading={false}
                  editionSize={
                    isUnlimitedSize(token?.size ?? 0) ? null : token?.size
                  }
                  useInfinitySymbol={false}
                />
              )}
            </>
          }
        />
      )}
      {parseFloat(collection?.royalty?.royaltyPercentageString ?? "0") > 0 && (
        <DetailSectionItem
          title="Creator royalty"
          content={`${collection?.royalty?.royaltyPercentageString ?? "0"}%`}
        />
      )}
      {collection?.usingOpenseaBlocklist && (
        <DetailSectionItem
          title={
            <Group spacing={7}>
              <div>Using CORI Operator Filter Registry</div>
              <InfoCircleFill
                width={17}
                height={17}
                cursor={"pointer"}
                onClick={() => setOpenseaBlocklistModalOpen(true)}
              />
            </Group>
          }
          content="Yes"
        />
      )}
      {mintVector && (
        <DetailSectionItem
          title="Mint vector ID"
          content={
            mintVectorsLoading ? (
              "Loading ..."
            ) : mintVectorIdProps.isDirectMint &&
              mintVector.priceType != PriceType.DutchAuction ? (
              mintVectorId
            ) : (
              <Group spacing={4}>
                {isSmall ? (
                  <CopyText
                    displayText={maskedMintVectorId}
                    copyValue={mintVectorId}
                  ></CopyText>
                ) : (
                  maskedMintVectorId
                )}
                <CopyButton copyValue={mintVectorId} />
              </Group>
            )
          }
        />
      )}
      {isImported && importedPlatformInfo && (
        <DetailSectionItem
          title="Imported from"
          content={
            <Group spacing={8}>
              <importedPlatformInfo.icon width={16} />
              {importedPlatformInfo.name}
            </Group>
          }
        />
      )}
      <OpenseaRegistryInfoModal
        isOpen={openseaBlocklistModalOpen}
        onClose={() => setOpenseaBlocklistModalOpen(false)}
        onCreateCollectionPage={false}
      />
      {isSeriesMint &&
        collection?.reveal &&
        !collection.flagVariations.sequentialDistributionSeries && (
          <Text size="xs" color={TEXT_COLOR.SECONDARY}>
            We use an independently verifiable, deterministic algorithm to
            ensure random token selection is fair.{" "}
            <Anchor
              size="xs"
              weight="bold"
              href={`${EXTERNAL_URL.RANDOMIZATION_ALGO_HELP_ARTICLE}`}
            >
              Read more
            </Anchor>{" "}
            about how we ensure randomness is fair and decentralized.
          </Text>
        )}
    </>
  );
};

export const DetailSectionItem = ({
  title,
  content,
  link,
}: DetailSectionColumnProps) => (
  <DetailSectionColumn
    title={
      <Text size="xs" color={TEXT_COLOR.SECONDARY}>
        {title}
      </Text>
    }
    content={<Text size="sm">{content}</Text>}
    link={link}
  />
);
