import { Info } from "@hl/base-components/lib/assets/icons.generated";
import { STATUS_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import {
  buildTxUrl,
  networkLookup,
} from "@hl/shared-features/lib/utils/blockExplorer";
import { Anchor, Card, Group, Stack, Text } from "@mantine/core";

const TransferInProgressBox = ({
  txHash = "",
  address,
  chainId,
}: {
  txHash?: string;
  address: string;
  chainId: number;
}) => {
  const blockchain = networkLookup(chainId);
  const txUrl = buildTxUrl(blockchain.type, txHash);
  return (
    <Stack>
      <Stack spacing={4}>
        <Text component="label" size="lg" fw={WEIGHT_BOLD}>
          Transferring to:
        </Text>
        <Text size="lg" fw={WEIGHT_BOLD}>
          {address}
        </Text>
      </Stack>
      <Card
        bg={STATUS_COLOR.INFORMATIVE_WASH}
        sx={(theme) => ({
          borderColor: theme.fn.themeColor(STATUS_COLOR.INFORMATIVE),
        })}
      >
        <Group align="center" noWrap>
          <Info />
          <Stack spacing={4}>
            <Text size="sm" fw={WEIGHT_BOLD}>
              Your transfer has started
            </Text>
            <Text size="xs">
              The {blockchain.displayName} network is processing your
              transaction, which can take a little while. See the transaction{" "}
              <Anchor
                size="xs"
                fw={WEIGHT_BOLD}
                underline={false}
                href={txUrl}
                target="_blank"
                rel="noreferrer"
              >
                here
              </Anchor>
            </Text>
          </Stack>
        </Group>
      </Card>
    </Stack>
  );
};

export default TransferInProgressBox;
