import React from "react";

import { Divider, Anchor, createStyles, Group } from "@mantine/core";

export type DetailSectionLineProps = {
  leftContent: React.ReactNode;
  rightContent?: React.ReactNode;
  link?: string;
  onClick?: () => void;
  noDivider?: boolean;
};

const useStyles = createStyles({
  content: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&>div": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
});

export const DetailSectionLine = ({
  leftContent,
  rightContent,
  link,
  onClick,
  noDivider = false,
}: DetailSectionLineProps) => {
  const { classes } = useStyles();
  const content = (
    <>
      <Group spacing={0} position="apart">
        <div>{leftContent}</div>
        <div className={classes.content}>{rightContent}</div>
      </Group>
    </>
  );
  return (
    <>
      {link ? (
        <Anchor
          target="_blank"
          rel="noreferrer"
          href={link}
          onClick={onClick}
          variant="text"
        >
          {content}
        </Anchor>
      ) : (
        content
      )}

      {!noDivider && <Divider my={14} />}
    </>
  );
};
