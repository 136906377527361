import React, { ReactNode } from "react";

import { Divider, Anchor, createStyles, Stack } from "@mantine/core";

export type DetailSectionColumnProps = {
  title: ReactNode;
  content?: ReactNode;
  link?: string;
};

const useStyles = createStyles({
  content: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&>div": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
});

export const DetailSectionColumn = ({
  title,
  content,
  link,
}: DetailSectionColumnProps) => {
  const { classes } = useStyles();
  const Content = (
    <Stack spacing={0}>
      <div>{title}</div>
      <div className={classes.content}>{content}</div>
    </Stack>
  );
  return (
    <>
      {link ? (
        <Anchor target="_blank" rel="noreferrer" href={link} variant="text">
          {Content}
        </Anchor>
      ) : (
        Content
      )}

      <Divider my="sm" />
    </>
  );
};
