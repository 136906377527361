import FormatCrypto from "@hl/base-components/lib/FormatCrypto";
import {
  TEXT_COLOR,
  UTILITY_COLOR,
} from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { User } from "@hl/shared-features/lib/features/auth/User";
import { ResizedImage } from "@hl/shared-features/lib/features/image";
import { Card, Group, Stack, Text } from "@mantine/core";

import { PublicUserProfileQuery } from "~features/MintPage/queries.graphql.generated";
const TransferTokenBox = ({
  account,
  collectionName,
  tokenName,
  tokenImageUrl,
  listingAmount,
  listingSymbol,
}: {
  account?: PublicUserProfileQuery["getPublicAccountSettings"];
  collectionName: string;
  tokenName: string;
  tokenImageUrl: string;
  listingAmount?: string;
  listingSymbol?: string;
}) => {
  return (
    <Card>
      <Group align="center" noWrap spacing={12}>
        <Card p={8} bg={UTILITY_COLOR.TABLE_ROW_BACKGROUND}>
          <ResizedImage height={80} width={80} src={tokenImageUrl} />
        </Card>
        <Stack spacing={4}>
          <Text size="lg" fw={WEIGHT_BOLD}>
            {tokenName}
          </Text>
          <Text size="sm">{collectionName}</Text>
          {account && (
            <User
              avatarUrl={account.displayAvatar}
              displayName={account.displayName}
              walletAddresses={account.walletAddresses}
              verified={!!account.verified}
              imported={!!account.imported}
              enableLink
              textSize="xs"
              textColor={TEXT_COLOR.SECONDARY}
              avatarSize={18}
            />
          )}
        </Stack>
        {listingAmount && (
          <Text size="lg" fw={WEIGHT_BOLD} ml="auto">
            <FormatCrypto
              amount={listingAmount}
              symbol={listingSymbol}
              onlyText
            />
          </Text>
        )}
      </Group>
    </Card>
  );
};

export default TransferTokenBox;
