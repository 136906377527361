import useMantineMediaQueries from "@hl/base-components/lib/hooks/useMantineMediaQueries";
import { OUTLINE_COLOR } from "@hl/base-components/lib/theme/button";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import {
  FEATURE_FLAGS,
  useFeatureFlags,
} from "@hl/shared-features/lib/features/auth/hooks";
import { useModalStack } from "@hl/shared-features/lib/features/modal";
import { Button, Text } from "@mantine/core";

import { PublicUserProfileQuery } from "~features/MintPage/queries.graphql.generated";
import { TransferModal } from "~features/MintPage/transfer/TransferModal";
import useMintState from "~hooks/useMintState";

import {
  NftContractStandard,
  SeriesToken,
} from "../../../apollo/graphql.generated";

const TransferButton = ({
  onTransferComplete,
  account,
  token,
  listingAmount,
  listingSymbol,
}: {
  onTransferComplete: () => void;
  account?: PublicUserProfileQuery["getPublicAccountSettings"];
  token: Omit<SeriesToken, "attributes">;
  listingAmount?: string;
  listingSymbol?: string;
}) => {
  const { pushModal } = useModalStack();
  const { isMobile } = useMantineMediaQueries();
  const { collection, collectionType, chainId, walletAddress, address } =
    useMintState();
  const enableTransfers = useFeatureFlags(FEATURE_FLAGS.ENABLE_TRANSFERS);

  const handleOnClick = () =>
    pushModal(
      <TransferModal
        data={{
          collectionId: collection?.id || "",
          collectionType,
          collectionName: collection?.name || "",
          tokenId: token.tokenId!,
          tokenName: token.name,
          tokenImageUrl: token.imageUrl || "",
          chainId,
          from: walletAddress!,
          collectionAddress: address,
          listingAmount,
          listingSymbol,
          onTransferComplete,
          account,
          amountOwned: token.userBalance,
          standard: collection?.standard || NftContractStandard.ERC721,
        }}
      />,
      {
        size: isMobile ? "xs" : "md",
        title: (
          <Text size="md" fw={WEIGHT_BOLD}>
            Transfer
          </Text>
        ),
      }
    );

  const isOwner =
    walletAddress?.toLowerCase() === token.ownerAddress?.toLowerCase() ||
    (token.userBalance && token.userBalance > 0);

  if (!token.tokenId || !token.ownerAddress || !isOwner || !enableTransfers) {
    return <></>;
  }

  return (
    <Button color={OUTLINE_COLOR} fullWidth size="lg" onClick={handleOnClick}>
      Transfer
    </Button>
  );
};

export default TransferButton;
