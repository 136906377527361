import { Warn } from "@hl/base-components/lib/assets/icons.generated";
import { STATUS_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { USER_ROUTES } from "@hl/shared-features/lib/config";
import { useAuth } from "@hl/shared-features/lib/features/auth";
import { Anchor, Card, Group, Stack, Text } from "@mantine/core";
const TransferWarningBox = ({ hasListings }: { hasListings: boolean }) => {
  const { walletAddress } = useAuth();
  return (
    <Card
      bg={STATUS_COLOR.WARNING_WASH}
      sx={(theme) => ({
        borderColor: theme.fn.themeColor(STATUS_COLOR.WARNING),
      })}
    >
      <Group align="center" noWrap>
        <Warn />
        <Stack spacing={4}>
          <Text size="sm" fw={WEIGHT_BOLD}>
            Be careful
          </Text>
          <Text size="xs">
            You won&apos;t be able to revert this transfer, so please
            triple-check the recipient address above.
          </Text>
          {hasListings && (
            <Text size="xs" mt={4}>
              You have listings for this token that will be cancelled if the
              token is transferred.{" "}
              <Anchor
                href={USER_ROUTES.user.marketplace.listings.replace(
                  ":slug",
                  walletAddress ?? ""
                )}
                size="xs"
                fw={WEIGHT_BOLD}
                underline
                target="_blank"
                rel="noreferrer"
              >
                Manage listings.
              </Anchor>
            </Text>
          )}
        </Stack>
      </Group>
    </Card>
  );
};

export default TransferWarningBox;
