import { ethers } from "ethers";
export const getMintVectorId = (isDirectMint, mintVectorId, encodedOnchainId) => {
    const onchainMintVectorIdNotResolvedLabel = "Missing";
    if (isDirectMint) {
        if (!encodedOnchainId)
            return onchainMintVectorIdNotResolvedLabel;
        const tokens = encodedOnchainId.split(":");
        if (tokens.length !== 3)
            return onchainMintVectorIdNotResolvedLabel;
        return tokens[2];
    }
    else {
        if (!mintVectorId)
            return undefined;
        return ethers.utils.formatBytes32String(mintVectorId);
    }
};
