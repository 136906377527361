import * as Types from "../../../apollo/graphql.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type TokenDetailsCollectionQueryVariables = Types.Exact<{
  collectionId: Types.Scalars["String"];
}>;

export type TokenDetailsCollectionQuery = {
  getPublicCollection: {
    __typename: "Collection";
    id: string;
    name: string;
    description: string | null;
    address: string;
    chainId: number;
    size: number | null;
    supply: number | null;
    usingOpenseaBlocklist: boolean | null;
    creatorEns: string | null;
    openSeaCollectionUrl: string | null;
    reveal: boolean | null;
    baseUri: string | null;
    onChainBaseUri: string | null;
    collectionType: Types._CollectionType;
    type: Types.CollectionContractType;
    status: Types.CollectionStatus;
    contractUri: string | null;
    royalty: {
      __typename: "V1Royalty";
      royaltyPercentageString: string | null;
      royaltyRecipient: string | null;
    } | null;
    creatorAddresses: Array<{
      __typename: "EvmAddress";
      address: string;
      name: string | null;
    }> | null;
    seriesDetails: {
      __typename: "SeriesDetails";
      mirroredBaseUri: string | null;
    } | null;
    generativeDetails: {
      __typename: "GenerativeDetails";
      mirroredBaseUri: string | null;
    } | null;
    seriesImages: {
      __typename: "SeriesImages";
      urls: Array<string>;
      coverImageUrl: string;
    } | null;
    editions: Array<{
      __typename: "EditionToken";
      name: string;
      description: string;
      image: string;
      onChainImage: string;
      animation: string | null;
      size: number;
      onChainMetadata: string | null;
      remainingSupply: number | null;
      nonTransferable: boolean | null;
      royalty: {
        __typename: "V1Royalty";
        royaltyRecipient: string | null;
        royaltyPercentageString: string | null;
      } | null;
    }> | null;
    tokens: Array<{
      __typename: "GeneralToken";
      animation_url: string | null;
      image: string;
      name: string;
      description: string;
      id: string;
      nonTransferable: boolean | null;
      royalty: {
        __typename: "V1Royalty";
        royaltyPercentageString: string | null;
        royaltyRecipient: string | null;
      } | null;
    }> | null;
  };
};

export type TokenDetailsQueryVariables = Types.Exact<{
  collectionId: Types.Scalars["String"];
  tokenId: Types.Scalars["String"];
}>;

export type TokenDetailsQuery = {
  getPublicCollection: {
    __typename: "Collection";
    seriesToken: {
      __typename: "SeriesToken";
      id: string;
      tokenId: string | null;
      name: string;
      description: string | null;
      imageUrl: string | null;
      animationUrl: string | null;
      minted: boolean | null;
      ownerAddress: string | null;
      ownerEns: string | null;
      metadata: {
        __typename: "MarketplaceTokenMetadata";
        animationMimeType: string | null;
        animationUrlOriginal: string | null;
        imageMimeType: string | null;
        imageUrlOriginal: string | null;
      } | null;
      attributes: Array<{
        __typename: "SeriesTokenAttribute";
        name: string;
        value: string;
      }>;
    } | null;
  };
};

export type TokenDetailsNormalizedQueryVariables = Types.Exact<{
  collectionId: Types.Scalars["String"];
  tokenId: Types.Scalars["String"];
  fromS3: Types.Maybe<Types.Scalars["Boolean"]>;
}>;

export type TokenDetailsNormalizedQuery = {
  getPublicCollection: {
    __typename: "Collection";
    seriesTokenNormalized: {
      __typename: "SeriesToken";
      id: string;
      tokenId: string | null;
      name: string;
      description: string | null;
      imageUrl: string | null;
      animationUrl: string | null;
      minted: boolean | null;
      ownerAddress: string | null;
      ownerEns: string | null;
      metadata: {
        __typename: "MarketplaceTokenMetadata";
        animationMimeType: string | null;
        animationUrlOriginal: string | null;
        imageMimeType: string | null;
        imageUrlOriginal: string | null;
      } | null;
      attributes: Array<{
        __typename: "SeriesTokenAttribute";
        name: string;
        value: string;
      }>;
    } | null;
  };
};

export type TokensDetailsNormalizedQueryVariables = Types.Exact<{
  collectionId: Types.Scalars["String"];
  tokenIds: Array<Types.Scalars["String"]> | Types.Scalars["String"];
  fromS3: Types.Maybe<Types.Scalars["Boolean"]>;
  isImportedCollection: Types.Maybe<Types.Scalars["Boolean"]>;
  nonFilteredUserAddress: Types.Maybe<Types.Scalars["String"]>;
}>;

export type TokensDetailsNormalizedQuery = {
  getPublicCollection: {
    __typename: "Collection";
    tokensNormalized: Array<{
      __typename: "SeriesToken";
      id: string;
      tokenId: string | null;
      name: string;
      description: string | null;
      imageUrl: string | null;
      animationUrl: string | null;
      minted: boolean | null;
      ownerAddress: string | null;
      balance: number | null;
      ownerEns: string | null;
      userBalance: number | null;
      metadata: {
        __typename: "MarketplaceTokenMetadata";
        animationMimeType: string | null;
        animationUrlOriginal: string | null;
        imageMimeType: string | null;
        imageUrlOriginal: string | null;
      } | null;
      attributes: Array<{
        __typename: "SeriesTokenAttribute";
        name: string;
        value: string;
      }>;
    }> | null;
  };
};

export const TokenDetailsCollectionDocument = gql`
  query TokenDetailsCollection($collectionId: String!) {
    getPublicCollection(collectionId: $collectionId) {
      id
      name
      description
      address
      chainId
      size
      supply
      royalty {
        royaltyPercentageString
        royaltyRecipient
      }
      usingOpenseaBlocklist
      creatorEns
      creatorAddresses {
        address
        name
      }
      openSeaCollectionUrl
      reveal
      seriesDetails {
        mirroredBaseUri
      }
      generativeDetails {
        mirroredBaseUri
      }
      baseUri
      onChainBaseUri
      collectionType
      type
      status
      contractUri
      seriesImages {
        urls
        coverImageUrl
      }
      editions {
        name
        description
        image
        onChainImage
        animation
        size
        onChainMetadata
        remainingSupply
        nonTransferable
        royalty {
          royaltyRecipient
          royaltyPercentageString
        }
      }
      tokens {
        animation_url
        image
        name
        description
        id
        nonTransferable
        royalty {
          royaltyPercentageString
          royaltyRecipient
        }
      }
    }
  }
`;

/**
 * __useTokenDetailsCollectionQuery__
 *
 * To run a query within a React component, call `useTokenDetailsCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenDetailsCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenDetailsCollectionQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useTokenDetailsCollectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    TokenDetailsCollectionQuery,
    TokenDetailsCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TokenDetailsCollectionQuery,
    TokenDetailsCollectionQueryVariables
  >(TokenDetailsCollectionDocument, options);
}
export function useTokenDetailsCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TokenDetailsCollectionQuery,
    TokenDetailsCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TokenDetailsCollectionQuery,
    TokenDetailsCollectionQueryVariables
  >(TokenDetailsCollectionDocument, options);
}
export type TokenDetailsCollectionQueryHookResult = ReturnType<
  typeof useTokenDetailsCollectionQuery
>;
export type TokenDetailsCollectionLazyQueryHookResult = ReturnType<
  typeof useTokenDetailsCollectionLazyQuery
>;
export type TokenDetailsCollectionQueryResult = Apollo.QueryResult<
  TokenDetailsCollectionQuery,
  TokenDetailsCollectionQueryVariables
>;
export const TokenDetailsDocument = gql`
  query TokenDetails($collectionId: String!, $tokenId: String!) {
    getPublicCollection(collectionId: $collectionId) {
      seriesToken(tokenId: $tokenId) {
        id
        tokenId
        name
        description
        imageUrl
        animationUrl
        minted
        metadata {
          animationMimeType
          animationUrlOriginal
          imageMimeType
          imageUrlOriginal
        }
        ownerAddress
        ownerEns
        attributes {
          name
          value
        }
      }
    }
  }
`;

/**
 * __useTokenDetailsQuery__
 *
 * To run a query within a React component, call `useTokenDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenDetailsQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useTokenDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TokenDetailsQuery,
    TokenDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TokenDetailsQuery, TokenDetailsQueryVariables>(
    TokenDetailsDocument,
    options
  );
}
export function useTokenDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TokenDetailsQuery,
    TokenDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TokenDetailsQuery, TokenDetailsQueryVariables>(
    TokenDetailsDocument,
    options
  );
}
export type TokenDetailsQueryHookResult = ReturnType<
  typeof useTokenDetailsQuery
>;
export type TokenDetailsLazyQueryHookResult = ReturnType<
  typeof useTokenDetailsLazyQuery
>;
export type TokenDetailsQueryResult = Apollo.QueryResult<
  TokenDetailsQuery,
  TokenDetailsQueryVariables
>;
export const TokenDetailsNormalizedDocument = gql`
  query TokenDetailsNormalized(
    $collectionId: String!
    $tokenId: String!
    $fromS3: Boolean
  ) {
    getPublicCollection(collectionId: $collectionId) {
      seriesTokenNormalized(tokenId: $tokenId, fromS3: $fromS3) {
        id
        tokenId
        name
        description
        imageUrl
        animationUrl
        minted
        ownerAddress
        ownerEns
        metadata {
          animationMimeType
          animationUrlOriginal
          imageMimeType
          imageUrlOriginal
        }
        attributes {
          name
          value
        }
      }
    }
  }
`;

/**
 * __useTokenDetailsNormalizedQuery__
 *
 * To run a query within a React component, call `useTokenDetailsNormalizedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenDetailsNormalizedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenDetailsNormalizedQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      tokenId: // value for 'tokenId'
 *      fromS3: // value for 'fromS3'
 *   },
 * });
 */
export function useTokenDetailsNormalizedQuery(
  baseOptions: Apollo.QueryHookOptions<
    TokenDetailsNormalizedQuery,
    TokenDetailsNormalizedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TokenDetailsNormalizedQuery,
    TokenDetailsNormalizedQueryVariables
  >(TokenDetailsNormalizedDocument, options);
}
export function useTokenDetailsNormalizedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TokenDetailsNormalizedQuery,
    TokenDetailsNormalizedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TokenDetailsNormalizedQuery,
    TokenDetailsNormalizedQueryVariables
  >(TokenDetailsNormalizedDocument, options);
}
export type TokenDetailsNormalizedQueryHookResult = ReturnType<
  typeof useTokenDetailsNormalizedQuery
>;
export type TokenDetailsNormalizedLazyQueryHookResult = ReturnType<
  typeof useTokenDetailsNormalizedLazyQuery
>;
export type TokenDetailsNormalizedQueryResult = Apollo.QueryResult<
  TokenDetailsNormalizedQuery,
  TokenDetailsNormalizedQueryVariables
>;
export const TokensDetailsNormalizedDocument = gql`
  query TokensDetailsNormalized(
    $collectionId: String!
    $tokenIds: [String!]!
    $fromS3: Boolean
    $isImportedCollection: Boolean
    $nonFilteredUserAddress: String
  ) {
    getPublicCollection(collectionId: $collectionId) {
      tokensNormalized(
        tokenIds: $tokenIds
        fromS3: $fromS3
        isImportedCollection: $isImportedCollection
        nonFilteredUserAddress: $nonFilteredUserAddress
      ) {
        id
        tokenId
        name
        description
        imageUrl
        animationUrl
        minted
        ownerAddress
        balance
        ownerEns
        userBalance
        metadata {
          animationMimeType
          animationUrlOriginal
          imageMimeType
          imageUrlOriginal
        }
        attributes {
          name
          value
        }
      }
    }
  }
`;

/**
 * __useTokensDetailsNormalizedQuery__
 *
 * To run a query within a React component, call `useTokensDetailsNormalizedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokensDetailsNormalizedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokensDetailsNormalizedQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      tokenIds: // value for 'tokenIds'
 *      fromS3: // value for 'fromS3'
 *      isImportedCollection: // value for 'isImportedCollection'
 *      nonFilteredUserAddress: // value for 'nonFilteredUserAddress'
 *   },
 * });
 */
export function useTokensDetailsNormalizedQuery(
  baseOptions: Apollo.QueryHookOptions<
    TokensDetailsNormalizedQuery,
    TokensDetailsNormalizedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TokensDetailsNormalizedQuery,
    TokensDetailsNormalizedQueryVariables
  >(TokensDetailsNormalizedDocument, options);
}
export function useTokensDetailsNormalizedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TokensDetailsNormalizedQuery,
    TokensDetailsNormalizedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TokensDetailsNormalizedQuery,
    TokensDetailsNormalizedQueryVariables
  >(TokensDetailsNormalizedDocument, options);
}
export type TokensDetailsNormalizedQueryHookResult = ReturnType<
  typeof useTokensDetailsNormalizedQuery
>;
export type TokensDetailsNormalizedLazyQueryHookResult = ReturnType<
  typeof useTokensDetailsNormalizedLazyQuery
>;
export type TokensDetailsNormalizedQueryResult = Apollo.QueryResult<
  TokensDetailsNormalizedQuery,
  TokensDetailsNormalizedQueryVariables
>;
