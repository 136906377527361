import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { memo, useState, } from "react";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Anchor, createStyles, Stack, Text, } from "@mantine/core";
import { IconArrowRightBar } from "@tabler/icons";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import CollectionPreview from "./CollectionPreview";
import UserPreview from "./UserPreview";
const useStyles = createStyles(() => ({
    text: {
        "& > *:first-of-type": {
            marginBlockStart: "0em !important",
        },
        "& > *:last-of-type": {
            marginBlockEnd: "0em !important",
        },
    },
}));
const DescriptionMarkdown = ({ description, hasExpand, numChars, disableVideos, lineClamp, noPopover, ...rest }) => {
    var _a;
    const { classes } = useStyles();
    const enableExpanded = description.length > (numChars !== null && numChars !== void 0 ? numChars : 0);
    const [expanded, setExpanded] = useState(!hasExpand);
    const descriptionClean = (_a = description === null || description === void 0 ? void 0 : description.replace(/\\n/g, "\n")) !== null && _a !== void 0 ? _a : "";
    const finalText = expanded || !enableExpanded
        ? descriptionClean
        : `${descriptionClean.slice(0, numChars)}...`;
    const sizes = ["2em", "1.5em", "1.17em", "1em", "0.83em", "0.67em"];
    const getTitle = (props, order) => {
        const updatedProps = {
            ...props,
            style: {
                ...props.style,
                fontSize: sizes[order - 1],
                fontWeight: WEIGHT_BOLD,
            },
        };
        const CustomTag = `h${order}`;
        return _jsx(CustomTag, { ...updatedProps, children: props.children });
    };
    return (_jsxs(Stack, { spacing: 0, children: [_jsx(Text, { size: "sm", ...rest, onClick: () => hasExpand && enableExpanded ? setExpanded((exp) => !exp) : undefined, lineClamp: lineClamp, className: classes.text, children: _jsx(ReactMarkdown, { remarkPlugins: [gfm], components: {
                        a: (props) => {
                            var _a;
                            const href = ((_a = props.node.properties) === null || _a === void 0 ? void 0 : _a.href) || "";
                            if ((href.includes("collection:") || href.includes("user:")) &&
                                !noPopover) {
                                if (href.includes("collection:")) {
                                    return (_jsx(CollectionPreview, { ...props, size: rest.size, collectionId: href.split("collection:")[1] }));
                                }
                                if (href.includes("user:")) {
                                    return (_jsx(UserPreview, { ...props, size: rest.size, userId: href.split("user:")[1] }));
                                }
                            }
                            return (_jsx(Anchor, { ...props, sx: {
                                    fontSize: rest.size || 14,
                                    textDecoration: "underline",
                                    wordBreak: "break-word",
                                    textUnderlineOffset: 3,
                                } }));
                        },
                        h1: (props) => getTitle(props, 1),
                        h2: (props) => getTitle(props, 2),
                        h3: (props) => getTitle(props, 3),
                        h4: (props) => getTitle(props, 4),
                        h5: (props) => getTitle(props, 5),
                        h6: (props) => getTitle(props, 6),
                        p: (props) => (_jsx("p", { ...props, style: { ...props.style, marginBlock: "1em" }, children: props.children })),
                        img: (props) => {
                            var _a, _b;
                            if (disableVideos) {
                                return null;
                            }
                            if ((_a = props.src) === null || _a === void 0 ? void 0 : _a.startsWith("https://www.youtube.com/embed/")) {
                                return (_jsx("iframe", { width: "100%", style: { aspectRatio: "16/9" }, src: props.src, title: (_b = props.alt) !== null && _b !== void 0 ? _b : "YouTube embed", frameBorder: "0", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share", referrerPolicy: "strict-origin-when-cross-origin", allowFullScreen: true }));
                            }
                            return _jsx("img", { ...props, style: { maxWidth: "100%" } });
                        },
                    }, children: finalText }) }), !expanded && enableExpanded && (_jsxs(Text, { onClick: () => setExpanded(true), fw: WEIGHT_BOLD, size: "sm", sx: {
                    alignItems: "center",
                    display: "flex",
                    cursor: "pointer",
                    width: "fit-content",
                }, children: ["Read more\u00A0", _jsx(IconArrowRightBar, { width: 16 })] }))] }));
};
export default memo(DescriptionMarkdown);
