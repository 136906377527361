import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { memo, useCallback, useMemo, useState } from "react";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Anchor, Divider, Loader, Stack, Text, UnstyledButton, useMantineTheme, } from "@mantine/core";
import ProfilePlaceholderImage from "../../assets/images/profile-placeholder.png";
import { USER_ROUTES } from "../../config";
import { User } from "../auth/User";
import HoverCardHighlight from "../hover-card/HoverCardHighlight";
import { ResizedImage } from "../image";
import Socials from "../socials/Socials";
import DescriptionMarkdown from "./DescriptionMarkdown";
import { useUserPopupPreviewLazyQuery } from "./queries.graphql.generated";
const UserPreview = ({ userId, ...rest }) => {
    var _a;
    const [userPopupPreview, { data: userData, loading: loadingUser }] = useUserPopupPreviewLazyQuery();
    const theme = useMantineTheme();
    const user = userData === null || userData === void 0 ? void 0 : userData.getPublicAccountSettings;
    const imageUrl = (user === null || user === void 0 ? void 0 : user.displayAvatar) || (user === null || user === void 0 ? void 0 : user.avatarUrl) || ProfilePlaceholderImage;
    const [isLoaded, setIsLoaded] = useState(false);
    const fetchPopoverContent = useCallback(() => {
        userPopupPreview({
            variables: {
                slug: userId,
            },
        });
    }, [userId, userPopupPreview]);
    const link = USER_ROUTES.user.profile.replace(":slug", userId);
    const targetContent = useMemo(() => (_jsx(UnstyledButton, { component: "a", href: link, sx: {
            display: "inline-flex",
            "&:hover": {
                textDecoration: "underline",
                textUnderlineOffset: 3,
            },
        }, children: _jsx(Text, { component: "span", size: rest.size || 14, weight: WEIGHT_BOLD, sx: { wordBreak: "break-word" }, children: rest.children }) })), [link, rest.children, rest.size]);
    return (_jsx(HoverCardHighlight, { onBeforeOpen: fetchPopoverContent, canOpen: !!(userData === null || userData === void 0 ? void 0 : userData.getPublicAccountSettings), targetWrapperSx: { display: "inline-flex" }, targetContent: targetContent, children: _jsxs(Stack, { spacing: 0, onClick: (e) => e.stopPropagation(), children: [_jsx(Anchor, { href: link, target: "_blank", rel: "noopener noreferrer", h: 220, sx: {
                        borderBottom: `1px solid ${theme.colors.divider[0]}`,
                        transition: "height 0.05s ease-out",
                    }, mx: -16, mt: -16, children: _jsx(ResizedImage, { src: imageUrl, onLoad: () => setIsLoaded(true), styles: {
                            image: { height: "220px !important" },
                        }, sx: {
                            opacity: isLoaded ? 1 : 0,
                            transition: "opacity 0.15s ease-out",
                        } }) }), _jsxs(Stack, { pt: "md", spacing: 16, pb: 4, children: [_jsx(User, { avatarUrl: user === null || user === void 0 ? void 0 : user.displayAvatar, displayName: user === null || user === void 0 ? void 0 : user.displayName, walletAddresses: user === null || user === void 0 ? void 0 : user.walletAddresses, verified: !!(user === null || user === void 0 ? void 0 : user.verified), imported: !!(user === null || user === void 0 ? void 0 : user.imported), enableLink: true, avatarSize: 24, bold: true, textSize: "lg", numLines: "1" }), _jsx(DescriptionMarkdown, { size: "sm", description: (_a = user === null || user === void 0 ? void 0 : user.bio) !== null && _a !== void 0 ? _a : "", mb: 8, noPopover: true, lineClamp: 8 }), _jsx(Divider, {}), loadingUser && _jsx(Loader, { size: 16, mx: "auto" }), user && _jsx(Socials, { profile: user })] })] }) }));
};
export default memo(UserPreview);
