import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useCallback } from "react";
import { ChevronDown } from "@hl/base-components/lib/assets/icons.generated";
import { SECONDARY_COLOR } from "@hl/base-components/lib/theme/button";
import { Menu, Button, Box } from "@mantine/core";
import { ampli } from "../../ampli";
import { buildLooksRareTokenUrl } from "../../utils/looksrare";
import { buildOpenSeaTokenUrl } from "../../utils/opensea";
import { chainIdToNetworkType } from "../chain";
export const MarketplaceSelect = ({ address, tokenId, chainId, label, collectionId, collectionName, buttonProps, }) => {
    const networkType = chainIdToNetworkType(chainId);
    const openSeaUrl = buildOpenSeaTokenUrl(address, tokenId !== null && tokenId !== void 0 ? tokenId : "", networkType);
    const looksRareUrl = buildLooksRareTokenUrl(address, tokenId !== null && tokenId !== void 0 ? tokenId : "");
    const x2y2Url = `https://x2y2.io/eth/${address}/${tokenId}`;
    const blurUrl = `https://blur.io/asset/${address}/${tokenId}`;
    const trackView = useCallback((marketplace) => {
        if (!ampli.isLoaded) {
            return;
        }
        ampli.viewOnMarketplace({
            collectionId,
            tokenId,
            projectName: collectionName,
            marketplace,
            pagePath: window.location.pathname,
        });
    }, [collectionName, collectionId]);
    return (_jsxs(Menu, { position: "bottom-end", width: 220, withinPortal: true, children: [_jsx(Menu.Target, { children: _jsx(Box, { sx: { ...((buttonProps === null || buttonProps === void 0 ? void 0 : buttonProps.fullWidth) && { width: "100%" }) }, children: _jsx(Button, { rightIcon: _jsx(ChevronDown, {}), pr: 12, color: SECONDARY_COLOR, ...buttonProps, children: label !== null && label !== void 0 ? label : "View on marketplace" }) }) }), _jsxs(Menu.Dropdown, { children: [_jsx(Menu.Item, { onClick: () => {
                            trackView("OpenSea");
                            window.open(openSeaUrl, "_blank");
                        }, children: "OpenSea" }), chainId == 1 && (_jsxs(_Fragment, { children: [_jsx(Menu.Item, { onClick: () => {
                                    trackView("LooksRare");
                                    window.open(looksRareUrl, "_blank");
                                }, children: "LooksRare" }), _jsx(Menu.Item, { onClick: () => {
                                    trackView("X2Y2");
                                    window.open(x2y2Url, "_blank");
                                }, children: "X2Y2" }), _jsx(Menu.Item, { onClick: () => {
                                    trackView("Blur");
                                    window.open(blurUrl, "_blank");
                                }, children: "Blur" })] }))] })] }));
};
