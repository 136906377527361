import React from "react";

import { CursorClick01 } from "@hl/base-components/lib/assets/icons/HDS";
import {
  LayersThree,
  Cube,
  Eye,
  Image,
} from "@hl/base-components/lib/assets/icons.generated/index";
import { OUTLINE_COLOR } from "@hl/base-components/lib/theme/button";
import { envConfig } from "@hl/shared-features/lib/config";
import {
  Box,
  Button,
  Divider,
  Grid,
  Group,
  Loader,
  SimpleGrid,
  Stack,
  Tooltip,
} from "@mantine/core";

import {
  GetCollectionTagsAndCategoriesQuery,
  useGetCollectionTagsAndCategoriesQuery,
} from "~features/MintPage/components/DetailSection/tags-categories/queries.graphql.generated";

export type TagsCategoriesDetailsProps = {
  collectionId: string;
};

type CategoriesType =
  GetCollectionTagsAndCategoriesQuery["getPublicCollectionDetails"]["userCategories"];

export const TagsCategoriesDetails = ({
  collectionId,
}: TagsCategoriesDetailsProps) => {
  const { data, loading } = useGetCollectionTagsAndCategoriesQuery({
    variables: { collectionId },
  });

  const categories = data?.getPublicCollectionDetails.userCategories || [];
  const tags = data?.getPublicCollectionDetails.userTags || [];

  if (loading) return <Loader />;

  const findCategories = (type: string) =>
    categories
      .filter((category) => category.type === type)
      .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Stack pt={6}>
      <CategoryRow
        categories={findCategories("COLLECTION_TYPE")}
        label="Collection type"
        icon={<LayersThree height={16} width={16} />}
      />
      <CategoryRow
        categories={findCategories("STYLE")}
        label="Style"
        icon={<Eye height={16} width={16} />}
      />
      <CategoryRow
        categories={findCategories("SUBJECT")}
        label="Subject"
        icon={<Image height={16} width={16} />}
      />
      <CategoryRow
        categories={findCategories("FORMAT")}
        label="Format"
        icon={<Cube height={16} width={16} />}
      />
      <CategoryRow
        categories={findCategories("TECHNOLOGY")}
        label="Technology"
        icon={<CursorClick01 height={16} width={16} />}
      />
      <CategoryRow categories={findCategories("HIGHLIGHTED")} />
      {!!tags.length && (
        <SimpleGrid cols={2} spacing={8}>
          {tags.map((tag) => (
            <Button
              w="100%"
              component="a"
              size="xs"
              key={tag.slug}
              color={OUTLINE_COLOR}
              href={`${envConfig.home.baseUrl}/explore/tags?tags=${encodeURI(
                tag.slug
              )}`}
              fullWidth
            >
              # {tag.name}
            </Button>
          ))}
        </SimpleGrid>
      )}
    </Stack>
  );
};

const CategoryRow = ({
  categories,
  label,
  icon,
}: {
  categories: CategoriesType;
  label?: string;
  icon?: React.ReactNode;
}) => {
  if (!categories || categories.length === 0) return <></>;
  const link = (slug: string) =>
    `${envConfig.home.baseUrl}/explore/categories?categories=${encodeURI(
      slug
    )}`;
  return (
    <>
      <Grid align="center" gutter={8}>
        {icon && (
          <Grid.Col span="content">
            <Box w={16} h={16}>
              <Tooltip label={label}>{icon}</Tooltip>
            </Box>
          </Grid.Col>
        )}
        <Grid.Col span="auto">
          <Group spacing={8}>
            {categories.map((category) => (
              <Button
                component="a"
                size="xs"
                key={category.slug}
                color={OUTLINE_COLOR}
                href={link(category.slug)}
              >
                {category.name}
              </Button>
            ))}
          </Group>
        </Grid.Col>
      </Grid>
      <Divider />
    </>
  );
};
