import { memo } from "react";

import { User } from "@hl/shared-features/lib/features/auth/User";
import { GroupProps, Flex } from "@mantine/core";

import useMintState from "../../hooks/useMintState";

const CollectionCreatedBy = ({
  small = false,
  ...rest
}: {
  small?: boolean;
} & GroupProps) => {
  const { collection } = useMintState();
  const creator = collection?.creatorAccountSettings;
  return (
    <Flex {...rest} align="center">
      <User
        avatarUrl={creator?.displayAvatar}
        displayName={creator?.displayName}
        walletAddresses={creator?.walletAddresses}
        verified={!!creator?.verified}
        imported={!!creator?.imported}
        enableLink
        small={small}
      />
    </Flex>
  );
};
export default memo(CollectionCreatedBy);
