import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import { SkeletonList } from "@hl/base-components/lib/Skeleton";
import { Group, Stack, useMantineTheme, Skeleton, Table } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import UserSkeleton from "../../auth/UserSkeleton";
const CollectionActivitySkeleton = ({ showHeader = true, stackVertically = false, lineItems, }) => {
    const theme = useMantineTheme();
    const isTabletAndUp = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
    return (_jsxs(Table, { sx: {
            "thead tr th": {
                padding: "0 0 16px 0",
                align: "left",
            },
            "tbody tr td": {
                paddingLeft: 0,
                align: "left",
            },
        }, miw: !isTabletAndUp && stackVertically
            ? theme.breakpoints.xs
            : theme.breakpoints.md, children: [showHeader && (_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { style: { width: "12%" }, children: _jsx(Skeleton, { height: 16, width: 100 }) }), _jsx("th", { style: { width: "28%" }, children: _jsx(Skeleton, { height: 16, width: 100 }) }), _jsx("th", { style: { width: "12%" }, children: _jsx(Skeleton, { height: 16, width: 100 }) }), isTabletAndUp && (_jsxs(_Fragment, { children: [_jsx("th", { style: { width: "19%" }, children: _jsx(Skeleton, { height: 16, width: 100 }) }), _jsx("th", { style: { width: "19%" }, children: _jsx(Skeleton, { height: 16, width: 100 }) }), _jsx("th", { style: { width: "12%" }, children: _jsx(Skeleton, { height: 16, width: 100 }) })] }))] }) })), _jsx("tbody", { children: _jsx(SkeletonList, { count: lineItems ? lineItems : isTabletAndUp ? 4 : 1, skeleton: _jsxs("tr", { children: [_jsx("td", { children: _jsx(Skeleton, { height: 16, width: 100 }) }), _jsx("td", { children: _jsxs(Group, { noWrap: true, children: [_jsx(Skeleton, { width: 64, height: 64 }), _jsx(Stack, { spacing: 0, children: _jsx(Skeleton, { height: 16, width: 50 }) })] }) }), _jsx("td", { children: _jsx(Skeleton, { height: 16, width: 100 }) }), isTabletAndUp && (_jsxs(_Fragment, { children: [_jsx("td", { children: _jsx(UserSkeleton, {}) }), _jsx("td", { children: _jsx(UserSkeleton, {}) }), _jsx("td", { children: _jsx(Skeleton, { height: 16, width: 100 }) })] }))] }) }) })] }));
};
export default memo(CollectionActivitySkeleton);
