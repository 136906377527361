import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { cloneElement, useCallback, useEffect, useMemo, useState, } from "react";
import { Label } from "@hl/base-components/lib/Label";
import SimpleIntersectionObserver from "@hl/base-components/lib/SimpleIntersectionObserver";
import { ArrowSquareRight, ArrowUpRight, CloseXSm, Hand, Mint, ShoppingCart, TagFill, } from "@hl/base-components/lib/assets/icons.generated";
import { BACKGROUND_COLOR, TEXT_COLOR, } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Anchor, Box, Center, Chip, createStyles, Flex, Group, Stack, Table, Text, } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useCollectionActivity, useTokenActivity, } from "@reservoir0x/reservoir-kit-ui";
import dayjs, { extend } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { formatEther } from "ethers/lib/utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { getDetailPageUrl } from "../../../config";
import useDetectSticky from "../../../hooks/useDetectSticky";
import { buildTxUrl, networkLookup } from "../../../utils/blockExplorer";
import { UserResolved } from "../../auth/User";
import { DFSFallbackImage } from "../../image/DFSFallbackImage";
import CollectionActivitySkeleton from "./CollectionActivitySkeleton";
extend(relativeTime);
export const useStyles = createStyles({
    label: {
        padding: "0 10px",
    },
    root: {
        flexWrap: "nowrap",
        flexShrink: 0,
    },
});
const CollectionActivity = ({ collection: { id: collectionId, onchainId, address, chainId, marketplaceId }, stackVertically, tokenId, onImageError, }) => {
    const reservoirCollectionId = (marketplaceId === null || marketplaceId === void 0 ? void 0 : marketplaceId.split(/:/).slice(1).join(":")) || address;
    const [filters, setFilters] = useState([ActivityAggregation.ALL]);
    const { classes, theme } = useStyles();
    const isSmallerThanLg = useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`);
    const isTabletAndUp = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
    const [headerHeight, setHeaderHeight] = useState("0px");
    const { isSticky, ref } = useDetectSticky(undefined, {
        threshold: [1],
        rootMargin: `-${headerHeight} 0px 0px 0px`,
    });
    useEffect(() => {
        const height = getComputedStyle(document.documentElement).getPropertyValue("--mantine-header-height");
        const adjustedHeight = parseInt(height.split("px")[0]) + 2;
        if (height) {
            setHeaderHeight(String(adjustedHeight) + "px");
        }
    }, []);
    const { data: tokenData, hasNextPage: hasNextTokenPage, fetchNextPage: fetchNextTokenPage, isFetchingPage: isFetchingTokenPage, } = useTokenActivity(`${address}:${tokenId}`, { limit: 20, types: getActivityFilters(filters) }, { isPaused: () => !tokenId });
    const { data: collectionData, hasNextPage: hasNextCollectionPage, fetchNextPage: fetchNextCollectionPage, isFetchingPage: isFetchingCollectionPage, } = useCollectionActivity({
        collection: reservoirCollectionId,
        limit: 20,
        types: getActivityFilters(filters),
    }, { isPaused: () => !!tokenId }, chainId);
    const data = useMemo(() => tokenId
        ? tokenData
        : collectionData, [tokenId, tokenData, collectionData]);
    const hasNextPage = useMemo(() => (tokenId ? hasNextTokenPage : hasNextCollectionPage), [tokenId, hasNextTokenPage, hasNextCollectionPage]);
    const fetchNextPage = useCallback(() => (tokenId ? fetchNextTokenPage() : fetchNextCollectionPage()), [tokenId, fetchNextTokenPage, fetchNextCollectionPage]);
    const isFetchingPage = useMemo(() => (tokenId ? isFetchingTokenPage : isFetchingCollectionPage), [tokenId, isFetchingTokenPage, isFetchingCollectionPage]);
    return (_jsxs(Flex, { gap: 32, wrap: "nowrap", align: "flex-start", mx: stackVertically ? -16 : 0, direction: stackVertically || isSmallerThanLg ? "column" : "row", children: [_jsxs(Stack, { px: stackVertically ? 16 : 0, bg: BACKGROUND_COLOR.PRIMARY, sx: {
                    flex: 1,
                    zIndex: 1,
                    position: "sticky",
                    top: stackVertically ? 0 : `calc(var(--mantine-header-height, 0px))`,
                    width: "100%",
                    marginTop: -16,
                    borderBottom: stackVertically || (isSticky && isSmallerThanLg)
                        ? `1px solid ${theme.colors.divider[0]}`
                        : undefined,
                    paddingBottom: 16,
                    paddingTop: 12,
                }, ref: ref, children: [!stackVertically && (_jsx(Text, { size: "lg", fw: WEIGHT_BOLD, children: "Activity type" })), _jsxs(Chip.Group, { multiple: true, value: filters, onChange: (activities) => {
                            const lastSelectedActivity = activities.length
                                ? activities[activities.length - 1]
                                : null;
                            if (activities.length === 0 ||
                                lastSelectedActivity === ActivityAggregation.ALL) {
                                setFilters([ActivityAggregation.ALL]);
                            }
                            else {
                                setFilters(activities.filter((activity) => activity !== ActivityAggregation.ALL));
                            }
                        }, children: [_jsx(Chip, { value: ActivityAggregation.ALL, classNames: { label: classes.label, root: classes.root }, children: "All activity" }), _jsx(Chip, { value: ActivityAggregation.SALE, classNames: { label: classes.label, root: classes.root }, children: _jsxs(Group, { spacing: 8, children: [_jsx(StandardIcon, { icon: _jsx(ShoppingCart, {}) }), _jsx("span", { children: "Sale" })] }) }), _jsx(Chip, { value: ActivityAggregation.LISTING, classNames: { label: classes.label, root: classes.root }, children: _jsxs(Group, { spacing: 8, children: [_jsx(StandardIcon, { icon: _jsx(TagFill, {}) }), _jsx("span", { children: "Listing" })] }) }), _jsx(Chip, { value: ActivityAggregation.TRANSFER, classNames: { label: classes.label, root: classes.root }, children: _jsxs(Group, { spacing: 8, children: [_jsx(StandardIcon, { icon: _jsx(ArrowSquareRight, {}) }), _jsx("span", { children: "Transfer" })] }) }), _jsx(Chip, { value: ActivityAggregation.MINT, classNames: { label: classes.label, root: classes.root }, children: _jsxs(Group, { spacing: 8, children: [_jsx(StandardIcon, { icon: _jsx(Mint, {}) }), _jsx("span", { children: "Mint" })] }) }), _jsx(Chip, { value: ActivityAggregation.OFFER, classNames: { label: classes.label, root: classes.root }, children: _jsxs(Group, { spacing: 8, children: [_jsx(StandardIcon, { icon: _jsx(Hand, {}) }), _jsx("span", { children: "Offer" })] }) })] })] }), _jsx(Stack, { px: stackVertically ? 16 : 0, w: stackVertically || isSmallerThanLg ? "100%" : undefined, spacing: 0, sx: {
                    flex: 5,
                    overflow: "scroll",
                }, children: data.length === 0 && isFetchingPage ? (_jsx(CollectionActivitySkeleton, { stackVertically: stackVertically, showHeader: true, lineItems: 5 })) : data.length === 0 && !isFetchingPage ? (_jsx(Center, { children: _jsx(Text, { children: "No activity" }) })) : (_jsxs(InfiniteScroll
                // scrollThreshold={1}
                , { 
                    // scrollThreshold={1}
                    dataLength: data.length, next: () => ({}), hasMore: hasNextPage, loader: null, children: [_jsxs(Table, { sx: {
                                "thead tr th": {
                                    padding: "0 0 16px 0",
                                },
                                "tbody tr td": {
                                    paddingLeft: 0,
                                },
                            }, miw: !isTabletAndUp && stackVertically
                                ? theme.breakpoints.xs
                                : theme.breakpoints.md, children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { style: { width: "12%" }, children: _jsx(GridLabel, { children: "ACTIVITY TYPE" }) }), _jsx("th", { style: { width: "28%" }, children: _jsx(GridLabel, { children: "ITEM" }) }), _jsx("th", { style: { width: "12%" }, children: _jsx(GridLabel, { children: "PRICE" }) }), (isTabletAndUp || !stackVertically) && (_jsxs(_Fragment, { children: [_jsx("th", { style: { width: "19%" }, children: _jsx(GridLabel, { children: "FROM" }) }), _jsx("th", { style: { width: "19%" }, children: _jsx(GridLabel, { children: _jsx(GridLabel, { children: "TO" }) }) }), _jsx("th", { style: { width: "12%" }, children: _jsx(GridLabel, { children: _jsx(GridLabel, { children: "TIME" }) }) })] }))] }) }), _jsx("tbody", { children: data === null || data === void 0 ? void 0 : data.map((activity, index) => {
                                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
                                        return (_jsxs("tr", { children: [_jsx("td", { children: _jsxs(Stack, { spacing: 4, children: [_jsxs(Group, { spacing: 6, align: "center", lh: 1, noWrap: true, children: [_jsx(StandardIcon, { icon: getActivityType(activity.type).icon }), _jsx(Text, { size: "sm", children: getActivityType(activity.type).name })] }), activity.type === "sale" && (_jsx(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, children: ((_b = (_a = activity.order) === null || _a === void 0 ? void 0 : _a.criteria) === null || _b === void 0 ? void 0 : _b.kind) === "token"
                                                                    ? `Via ${((_c = activity.order) === null || _c === void 0 ? void 0 : _c.side) === "ask"
                                                                        ? "listing"
                                                                        : "offer"}`
                                                                    : `Via collection ${((_d = activity.order) === null || _d === void 0 ? void 0 : _d.side) === "ask"
                                                                        ? "listing"
                                                                        : "offer"}` }))] }) }), _jsx("td", { children: _jsx(Link, { style: {
                                                            textDecoration: "none",
                                                        }, to: collectionId && ((_e = activity.token) === null || _e === void 0 ? void 0 : _e.tokenId)
                                                            ? getDetailPageUrl({
                                                                id: collectionId,
                                                                onchainId,
                                                            }, activity.token.tokenId)
                                                            : "", children: _jsxs(Group, { noWrap: true, children: [_jsx(DFSFallbackImage, { width: 64, height: 64, src: ((_f = activity.highlightToken) === null || _f === void 0 ? void 0 : _f.imageUrl) ||
                                                                        ((_g = activity.token) === null || _g === void 0 ? void 0 : _g.tokenImage) ||
                                                                        ((_h = activity.collection) === null || _h === void 0 ? void 0 : _h.collectionImage), onError: onImageError }), _jsxs(Stack, { spacing: 0, children: [((_j = activity.token) === null || _j === void 0 ? void 0 : _j.tokenId) && (_jsx(Text, { size: "sm", fw: WEIGHT_BOLD, children: `#${activity.token.tokenId}` })), _jsx(Text, { size: "sm", children: ((_k = activity.token) === null || _k === void 0 ? void 0 : _k.tokenName) ||
                                                                                ((_l = activity.collection) === null || _l === void 0 ? void 0 : _l.collectionName) })] })] }) }) }), _jsx("td", { children: activity.type === "transfer" || activity.type === "mint"
                                                        ? "-"
                                                        : ((_o = (_m = activity.price) === null || _m === void 0 ? void 0 : _m.amount) === null || _o === void 0 ? void 0 : _o.raw) &&
                                                            `${formatEther(activity.price.amount.raw)} ${((_p = activity.price.currency) === null || _p === void 0 ? void 0 : _p.symbol) || ""}` }), (isTabletAndUp || !stackVertically) && (_jsxs(_Fragment, { children: [_jsx("td", { children: activity.fromAddress && activity.type !== "mint" ? (_jsx(UserResolved, { walletAddresses: [activity.fromAddress] })) : ("-") }), _jsxs("td", { children: [" ", activity.toAddress ? (_jsx(UserResolved, { walletAddresses: [activity.toAddress] })) : ("-")] }), _jsx("td", { children: activity.txHash ? (_jsxs(Group, { spacing: 4, noWrap: true, children: [_jsx(Anchor, { target: "_blank", href: buildTxUrl(networkLookup(chainId).type, activity.txHash), size: "sm", children: dayjs().to((activity.timestamp || 0) * 1000) }), _jsx(Box, { w: 14, h: 14, children: _jsx(ArrowUpRight, { width: 14, height: 14 }) })] })) : (dayjs().to((activity.timestamp || 0) * 1000)) })] }))] }, index));
                                    }) })] }), !isFetchingPage && hasNextPage && (_jsx(SimpleIntersectionObserver, { onIntersection: (e) => {
                                if (e.isIntersecting) {
                                    fetchNextPage();
                                }
                            }, children: _jsx("pre", { children: _jsx(CollectionActivitySkeleton, { showHeader: false }) }) }))] })) })] }));
};
export const getActivityType = (type) => {
    switch (type) {
        case "sale":
            return {
                name: "Sale",
                icon: _jsx(ShoppingCart, {}),
            };
        case "ask":
            return {
                name: "Listing",
                icon: _jsx(TagFill, {}),
            };
        case "transfer":
            return {
                name: "Transfer",
                icon: _jsx(ArrowSquareRight, {}),
            };
        case "mint":
            return {
                name: "Mint",
                icon: _jsx(Mint, {}),
            };
        case "bid":
            return {
                name: "Offer",
                icon: _jsx(Hand, {}),
            };
        case "bid_cancel":
            return {
                name: "Offer Cancelled",
                icon: _jsx(CloseXSm, {}),
            };
        case "ask_cancel":
            return {
                name: "Listing Cancelled",
                icon: _jsx(CloseXSm, {}),
            };
        default:
            return {
                name: "Unknown",
                icon: _jsx(CloseXSm, {}),
            };
    }
};
const GridLabel = ({ children }) => (_jsx(Label, { color: TEXT_COLOR.SECONDARY, children: children }));
export const StandardIcon = ({ icon, }) => cloneElement(icon, { width: 20, height: 20 });
var ActivityAggregation;
(function (ActivityAggregation) {
    ActivityAggregation["ALL"] = "ALL";
    ActivityAggregation["SALE"] = "SALE";
    ActivityAggregation["LISTING"] = "LISTING";
    ActivityAggregation["TRANSFER"] = "TRANSFER";
    ActivityAggregation["MINT"] = "MINT";
    ActivityAggregation["OFFER"] = "OFFER";
})(ActivityAggregation || (ActivityAggregation = {}));
const ALL_ACTIVITIES = [
    "sale",
    "ask",
    "transfer",
    "mint",
    "bid",
    "bid_cancel",
    "ask_cancel",
];
const getActivityFilters = (activities) => {
    if (activities.includes(ActivityAggregation.ALL)) {
        return ALL_ACTIVITIES;
    }
    return activities.flatMap((value) => value === ActivityAggregation.SALE
        ? ["sale"]
        : value === ActivityAggregation.LISTING
            ? ["ask"]
            : value === ActivityAggregation.TRANSFER
                ? ["transfer"]
                : value === ActivityAggregation.MINT
                    ? ["mint"]
                    : value === ActivityAggregation.OFFER
                        ? ["bid"]
                        : ALL_ACTIVITIES);
};
export default CollectionActivity;
