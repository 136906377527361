import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import { CopyButton as MantineCopyButton, Flex, Tooltip, } from "@mantine/core";
const CopyText = ({ displayText, copyValue, preventDefault, justify = "center", }) => (_jsx(MantineCopyButton, { value: copyValue, children: ({ copied, copy }) => (_jsx(Tooltip, { label: copied ? "Copied!" : "Copy", withArrow: true, onClick: (e) => {
            copy();
            if (preventDefault) {
                e.preventDefault();
            }
        }, children: _jsx(Flex, { sx: { cursor: "pointer" }, justify: justify, children: displayText }) })) }));
export default memo(CopyText);
